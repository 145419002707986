<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="suivi budget promoteur actualisation succès"
        class="icon"
        src="@/assets/event/NewDemand.svg"
      />
    </div>
    <div class="content mini">
      <span class="semi-bold">
        {{ notification.payload.authorFirstName }}
        {{ notification.payload.authorLastName }}
      </span>
      de
      <span class="semi-bold">
        {{ notification.payload.authorGroup }}
      </span>
      a actualisé un budget sur l'opération
      <span class="semi-bold">
        {{ notification.payload.operationName }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotificationBannerListItemSuiviBudgetPromoteurActualisationSucces",
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
