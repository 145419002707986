export enum SuiviBudgetPoste {
  TERRAIN_NUMERAIRE = "TERRAIN_NUMERAIRE",
  TERRAIN_NUMERAIRE_SANS_TVA = "TERRAIN_NUMERAIRE_SANS_TVA",
  TERRAIN_DATION = "TERRAIN_DATION",
  FRAIS_ACQUISITION = "FRAIS_ACQUISITION",
  INDEMNITES = "INDEMNITES",
  COMMISSIONS = "COMMISSIONS",
  TAXES_PARTICIPATIONS = "TAXES_PARTICIPATIONS",
  DIVERS_FONCIER = "DIVERS_FONCIER",
  DEPOLLUTION = "DEPOLLUTION",
  DEMOLITION = "DEMOLITION",
  FONDATIONS = "FONDATIONS",
  TRAVAUX = "TRAVAUX",
  TRAVAUX_VRD = "TRAVAUX_VRD",
  TRAVAUX_IMPREVUS = "TRAVAUX_IMPREVUS",
  GEOMETRE = "GEOMETRE",
  ASSURANCES = "ASSURANCES",
  HONORAIRES_ARCHITECTE = "HONORAIRES_ARCHITECTE",
  HONORAIRES_TECHNIQUES_DIVERS = "HONORAIRES_TECHNIQUES_DIVERS",
  FRAIS_ADMINISTRATIFS = "FRAIS_ADMINISTRATIFS",
  FRAIS_ANNEXES = "FRAIS_ANNEXES",
  PUBLICITE = "PUBLICITE",
  AIDES_VENTE = "AIDES_VENTE",
  HONORAIRES_COMMERCIALISATION = "HONORAIRES_COMMERCIALISATION",
  HONORAIRES_GESTION = "HONORAIRES_GESTION",
  COMMISSIONS_SUR_ENGAGEMENT = "COMMISSIONS_SUR_ENGAGEMENT",
  INTERETS = "INTERETS",
  VENTE_TVA_20 = "VENTE_TVA_20",
  VENTE_TVA_10 = "VENTE_TVA_10",
  VENTE_TVA_5_5 = "VENTE_TVA_5_5",
  DATION = "DATION",
  DIVERS_RECETTES = "DIVERS_RECETTES",
  IGNORE = "IGNORE",
  EMPTY = "EMPTY",
}

type SuiviBudgetPosteWithoutLigneType =
  | SuiviBudgetPoste.IGNORE
  | SuiviBudgetPoste.EMPTY;

export type SuiviBudgetPosteHavingLigneType = Exclude<
  SuiviBudgetPoste,
  SuiviBudgetPosteWithoutLigneType
>;

export function hasSuiviBudgetPosteLigneType(
  poste: SuiviBudgetPoste
): poste is SuiviBudgetPosteHavingLigneType {
  return ![SuiviBudgetPoste.IGNORE, SuiviBudgetPoste.EMPTY].includes(poste);
}
