import { TiersUserType } from "../enum/tiersUserType";
import { TiersUserRole } from "../enum/tiersUserRole";
import { IsBoolean, IsEmail, IsEnum, ValidateIf } from "class-validator";

export class TiersUserCreationRequest {
  @IsEmail()
  email: string;

  @IsEnum(TiersUserType)
  tiersType: TiersUserType;

  @IsEnum(TiersUserRole)
  @ValidateIf((tierCreationRequest) => !tierCreationRequest.isMailEnabled)
  role?: TiersUserRole | null;

  @IsBoolean()
  isMailEnabled: boolean;
}
