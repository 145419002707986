// Returns every variant of an enumeration K.
// Works with string enums.
export function enumVariants<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  // Typescript black magic. An enumeration is just an object with its variants as keys in Typescript.
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export function enumValues<O extends object>(obj: O): O[keyof O][] {
  return enumVariants(obj).map((k) => obj[k]);
}

/** Utilitaire pour créer à partir d'un objet une Map dont les clés appartiennent à un enum */
export function createMapWithEnumAsKeys<
  Tkeys extends string,
  T extends Record<Tkeys, unknown>,
  K extends Tkeys,
>(obj: T, keys: K[]): Map<K, T[K]> {
  const result = new Map<K, T[K]>();
  for (const key of keys) {
    if (key in obj) {
      result.set(key, obj[key]);
    }
  }
  return result;
}

/**
 * Crée un type guard pour vérifier si une valeur est une des valeurs d'un enum
 * @param e Enum
 * @returns Type guard pour l'enum e
 * @see https://stackoverflow.com/a/58278753
 */
export function isSomeEnum<T extends { [s: string]: unknown }>(e: T) {
  return (token: unknown): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T]);
}
