import { ActeSecondaireLight } from "@/domain/acteSecondaireLight";
import { AvenantLight } from "@/domain/avenantLight";
import { Demande } from "@/domain/demande";
import { DemandeDocumentValidation } from "@/domain/demandeDocumentValidation";
import { DemandeLight } from "@/domain/dto/demandeLight";
import type { DemandeSignataireCreate } from "@domain/dto/demandeSignataireCreate";
import { DemandeSignataireCreateRequest } from "@domain/dto/demandeSignataireCreateRequest";
import { PoolAccessMode } from "@domain/enum/poolAccessMode";
import { SignataireHeavy } from "@domain/dto/signataireHeavy";
import { AccessType } from "@/domain/enum/accessType";
import { DemandeSharingType } from "@domain/enum/demandeSharingType";
import { DemandeStatus } from "@domain/enum/demandeStatus";
import { DemandeStep } from "@domain/enum/demandeStep";
import { DemandeType } from "@domain/enum/demandeType";
import { EngagementGlobalType } from "@domain/enum/engagementGlobalType";
import { MainleveeStatus } from "@domain/enum/mainleveeStatus";
import { Role } from "@domain/enum/role";
import { SignMethod } from "@domain/enum/signMethod";
import { SocieteSupportType } from "@domain/enum/societeSupportType";
import { ValidationStatus } from "@domain/enum/validationStatus";
import { HommeDeArtDemandeHeavy } from "@/domain/hommeDeArtDemandeHeavy";
import {
  isSignMethodElectronic,
  isSignMethodManual,
} from "@/utils/signatureUtils";
import { differenceInBusinessDays } from "date-fns";
import { formatDate, formatDateTime } from "./dateUtils";
import { DemandeUtilisateurTiers } from "@/domain/demandeUtilisateurTiers";
import { TiersUserRole } from "@domain/enum/tiersUserRole";
import { hasRole } from "@/utils/groupUtils";
import {
  ActeSecondaireLightArchived,
  AvenantLightArchived,
  DemandeArchive,
} from "@/domain/operationArchivedHeavy";
import { SignataireType } from "@domain/enum/signataireType";

export function createDemande(demande: Partial<Demande> = {}): Demande {
  return {
    id: "",
    name: "",
    idOperation: "",
    euroCentimeAmount: 0,
    expectedDate: new Date(),
    createdAt: new Date(),
    statusBanque: ValidationStatus.UNCHECKED,
    statusPromoteur: ValidationStatus.UNCHECKED,
    type: DemandeType.GARANTIE_FINANCIERE_ACHEVEMENT,
    step: DemandeStep.PREPARATION,
    signMethod: SignMethod.UNCHECKED,
    demandeSharingType: DemandeSharingType.BILATERAL,
    idBanque: "",
    status: DemandeStatus.PREPARATION,
    sureteList: [],
    signataireBanqueCount: 1,
    signatairePromoteurCount: 1,
    signataireList: [],
    signataireExterneList: [],
    updatedAt: new Date(),
    societeSupport: {
      id: "",
      name: "",
      createdAt: new Date(),
      creationInProgress: false,
      idPromoteur: "",
      siren: "",
      type: SocieteSupportType.FILIALE,
      accessType: AccessType.NORMAL,
      promoteur: {
        id: "",
        name: "",
        roles: [],
        users: [],
        monoSignaturePromoteur: [],
        doubleSignaturePromoteur: [],
        promoteursSignatairesGfa: [],
        reportingColor: "",
        logoColor: "",
        idLogoGroup: "",
      },
    },
    avenantList: [],
    acteSecondaireList: [],
    documentValidationList: [],
    cmpList: [],
    demandeUtilisateurTiersList: [],
    isSignatureOrdered: false,
    ...demande,
  };
}

export function demandeTypeLabel(demandeType: DemandeType): string {
  switch (demandeType) {
    case DemandeType.GARANTIE_FINANCIERE_ACHEVEMENT:
      return "Garantie financière d’achèvement (GFA)";
    case DemandeType.CAUTION_INDEMNITE_IMMOBILISATION:
      return "Caution d’indemnité d’immobilisation";
    case DemandeType.GARANTIE_PAIEMENT_TERME:
      return "Caution de paiement à terme";
    case DemandeType.CAUTION_PAIEMENT_TRAVAUX:
      return "Caution de paiement des travaux";
    case DemandeType.AUTRE_CAUTION:
      return "Autre caution";
    case DemandeType.CREDIT_TERRAIN:
      return "Crédit terrain";
    case DemandeType.CREDIT_ACCOMPAGMENT:
      return "Crédit d'accompagnement";
    case DemandeType.AUTRE_CREDIT:
      return "Autre crédit";
    default:
      return "";
  }
}

export function poolAccessLabel(poolAccessMode: PoolAccessMode): string {
  switch (poolAccessMode) {
    case PoolAccessMode.POOL_PARTICIPANT:
      return "Participant";
    case PoolAccessMode.POOL_CHEF_FILE:
      return "Chef de file";
    case PoolAccessMode.UNSHARED:
      return "Opérations sans pool";
    default:
      return "";
  }
}

export function demandeAmoutLabel(demandeType: DemandeType): string {
  if (demandeType === DemandeType.GARANTIE_FINANCIERE_ACHEVEMENT) {
    return "Chiffre d'affaires TTC";
  }
  return "Montant de l'engagement souhaité";
}

export function getEngagementGlobalTypeToDemandeType(
  engagementGlobalTypeList: EngagementGlobalType[]
): DemandeType[] {
  let demandeTypeList: DemandeType[] = [];
  if (engagementGlobalTypeList.includes(EngagementGlobalType.GFA)) {
    demandeTypeList = [
      ...demandeTypeList,
      DemandeType.GARANTIE_FINANCIERE_ACHEVEMENT,
    ];
  }
  if (engagementGlobalTypeList.includes(EngagementGlobalType.CAUTION)) {
    demandeTypeList = [
      ...demandeTypeList,
      DemandeType.CAUTION_INDEMNITE_IMMOBILISATION,
      DemandeType.GARANTIE_PAIEMENT_TERME,
      DemandeType.CAUTION_PAIEMENT_TRAVAUX,
      DemandeType.AUTRE_CAUTION,
    ];
  }
  if (engagementGlobalTypeList.includes(EngagementGlobalType.CREDIT)) {
    demandeTypeList = [
      ...demandeTypeList,
      DemandeType.CREDIT_TERRAIN,
      DemandeType.CREDIT_ACCOMPAGMENT,
      DemandeType.AUTRE_CREDIT,
    ];
  }
  return demandeTypeList;
}

export function isDemandeSignedOrDatePassedOrMainLevee(
  status: DemandeStatus | DemandeStep
): boolean {
  const acceptedStatus: (DemandeStatus | DemandeStep)[] = [
    DemandeStatus.SIGNE,
    DemandeStatus.EN_ATTENTE_DE_MAINLEVEE,
    DemandeStatus.DATE_ACHEVEMENT_DEPASSEE,
    DemandeStatus.MAIN_LEVEE_REFUSEE,
    DemandeStatus.MAIN_LEVEE_EFFECTUEE,
  ];
  return acceptedStatus.includes(status);
}

export function isDemandeMainLevee(
  status: DemandeStatus | DemandeStep
): boolean {
  const acceptedStatus: (DemandeStatus | DemandeStep)[] = [
    DemandeStatus.EN_ATTENTE_DE_MAINLEVEE,
    DemandeStatus.DATE_ACHEVEMENT_DEPASSEE,
    DemandeStatus.MAIN_LEVEE_REFUSEE,
    DemandeStatus.MAIN_LEVEE_EFFECTUEE,
  ];
  return acceptedStatus.includes(status);
}

export function isDemandeDatePassedOrMainLevee(status: DemandeStatus): boolean {
  const acceptedStatus = [
    DemandeStatus.EN_ATTENTE_DE_MAINLEVEE,
    DemandeStatus.DATE_ACHEVEMENT_DEPASSEE,
    DemandeStatus.MAIN_LEVEE_REFUSEE,
    DemandeStatus.MAIN_LEVEE_EFFECTUEE,
  ];
  return acceptedStatus.includes(status);
}

export function isDemandeSignedOrDatePassed(status: DemandeStatus): boolean {
  const acceptedStatus = [
    DemandeStatus.SIGNE,
    DemandeStatus.DATE_ACHEVEMENT_DEPASSEE,
  ];
  return acceptedStatus.includes(status);
}

export function shouldPromptUserToAddMainlevee(
  hasDemandeActeSecondaire: boolean,
  demandePrincipale: Demande | ActeSecondaireLight
): boolean {
  return (
    isDemandeSignedOrDatePassed(demandePrincipale.status) &&
    !hasDemandeActeSecondaire &&
    isGfaType(demandePrincipale.type)
  );
}

export function isDemandeSigned(step: DemandeStep): boolean {
  const acceptedStatus = [
    DemandeStep.MAIN_LEVEE,
    DemandeStep.COMPLETED,
    DemandeStep.MAIN_LEVEE_EFFECTUEE,
    DemandeStep.MAIN_LEVEE_DEMANDE_COMPLEMENTAIRE,
  ];
  return acceptedStatus.includes(step);
}

export function demandeStatusLabel(status: DemandeStatus): string {
  switch (status) {
    case DemandeStatus.PARTAGEE:
      return "Demande partagée";
    case DemandeStatus.CO_CONSTRUCTION:
      return "Co-construction";
    case DemandeStatus.CONFIRMATION_A_VALIDER:
      return "Confirmation à valider";
    case DemandeStatus.EN_ATTENTE_DU_CHOIX_DE_SIGNATURE:
      return "En attente du choix de signature";
    case DemandeStatus.EN_ATTENTE_DE_SIGNATURE:
      return "En attente de signature";
    case DemandeStatus.DECLINED:
      return "En attente de signature";
    case DemandeStatus.SIGNE:
      return "Signé";
    case DemandeStatus.DATE_ACHEVEMENT_DEPASSEE:
      return "Date d'achèvement dépassée";
    case DemandeStatus.EN_ATTENTE_DE_MAINLEVEE:
      return "En attente de mainlevée";
    case DemandeStatus.MAIN_LEVEE_EFFECTUEE:
      return "Mainlevée effectuée";
    case DemandeStatus.MAIN_LEVEE_REFUSEE:
      return "Mainlevée refusée";
    default:
      return "Préparation";
  }
}

export function sentenceErrorAmountInput(demandeType: DemandeType): string {
  if (demandeType === DemandeType.GARANTIE_FINANCIERE_ACHEVEMENT) {
    return "Vous n'avez pas renseigné de CA";
  }
  return "Vous n'avez pas renseigné le montant d'engagement souhaité";
}

export function demandeStatusColor(status: DemandeStatus): string {
  switch (status) {
    case DemandeStatus.PREPARATION:
      return "yellow";
    case DemandeStatus.CO_CONSTRUCTION:
      return "yellow";
    case DemandeStatus.CONFIRMATION_A_VALIDER:
      return "orange";
    case DemandeStatus.EN_ATTENTE_DE_SIGNATURE:
      return "orange";
    case DemandeStatus.EN_ATTENTE_DU_CHOIX_DE_SIGNATURE:
      return "orange";
    case DemandeStatus.DECLINED:
      return "orange";
    case DemandeStatus.SIGNE:
      return "green";
    case DemandeStatus.DATE_ACHEVEMENT_DEPASSEE:
      return "red";
    case DemandeStatus.EN_ATTENTE_DE_MAINLEVEE:
      return "purple";
    case DemandeStatus.MAIN_LEVEE_REFUSEE:
      return "red";
    case DemandeStatus.MAIN_LEVEE_EFFECTUEE:
      return "grey";
    default:
      return "yellow";
  }
}

export const gfaTypes = [DemandeType.GARANTIE_FINANCIERE_ACHEVEMENT];

export const creditTypes = [
  DemandeType.CREDIT_TERRAIN,
  DemandeType.CREDIT_ACCOMPAGMENT,
  DemandeType.AUTRE_CREDIT,
];

export const cautionTypes = [
  DemandeType.CAUTION_INDEMNITE_IMMOBILISATION,
  DemandeType.GARANTIE_PAIEMENT_TERME,
  DemandeType.CAUTION_PAIEMENT_TRAVAUX,
  DemandeType.AUTRE_CAUTION,
];

export const signReadyStatus = [
  DemandeStatus.EN_ATTENTE_DU_CHOIX_DE_SIGNATURE,
  DemandeStatus.EN_ATTENTE_DE_SIGNATURE,
];

export const validationStatus = [DemandeStatus.CONFIRMATION_A_VALIDER];

export const allStatus = Object.values(DemandeStatus);

export const allTypes = Object.values(EngagementGlobalType);

export function isGfaType(type: DemandeType): boolean {
  return gfaTypes.includes(type);
}

export function isCreditType(type: DemandeType): boolean {
  return creditTypes.includes(type);
}

export function isCautionType(type: DemandeType): boolean {
  return cautionTypes.includes(type);
}

export function demandeCategoryLabel(demandeType: DemandeType): string {
  if (isGfaType(demandeType)) {
    return "GFA";
  } else if (isCautionType(demandeType)) {
    return "caution";
  } else if (isCreditType(demandeType)) {
    return "crédit";
  }
  return "";
}

const ConventionWorkflowCardNumber = new Map([
  [DemandeStep.PREPARATION, 1],
  [DemandeStep.CO_CONSTRUCTION, 2],
  [DemandeStep.SIGNATURE, 3],
]);
const AvenantOrAttestationWorkflowCardNumber = new Map([
  [DemandeStep.CO_CONSTRUCTION, 1],
  [DemandeStep.SIGNATURE, 2],
]);

const DemandeUnilateraleWorkflowCardNumber = new Map([
  [DemandeStep.PREPARATION, 1],
  [DemandeStep.CO_CONSTRUCTION, 2],
  [DemandeStep.SIGNATURE, 3],
]);

const DemandeUnilateraleAvenantWorkflowCardNumber = new Map([
  [DemandeStep.CO_CONSTRUCTION, 1],
  [DemandeStep.SIGNATURE, 2],
]);

const ConventionStepNumber = new Map([
  [DemandeStep.PREPARATION, 1],
  [DemandeStep.CO_CONSTRUCTION, 2],
  [DemandeStep.SIGNATURE, 3],
  [DemandeStep.COMPLETED, 4],
  [DemandeStep.MAIN_LEVEE, 4],
  [DemandeStep.MAIN_LEVEE_EFFECTUEE, 4],
]);

const AvenantOrAttestationStepNumber = new Map([
  [DemandeStep.CO_CONSTRUCTION, 1],
  [DemandeStep.SIGNATURE, 2],
  [DemandeStep.COMPLETED, 3],
  [DemandeStep.MAIN_LEVEE, 3],
  [DemandeStep.MAIN_LEVEE_EFFECTUEE, 3],
]);

const DemandeUnilateraleStepNumber = new Map([
  [DemandeStep.PREPARATION, 1],
  [DemandeStep.CO_CONSTRUCTION, 2],
  [DemandeStep.SIGNATURE, 3],
  [DemandeStep.COMPLETED, 4],
  [DemandeStep.MAIN_LEVEE, 4],
  [DemandeStep.MAIN_LEVEE_EFFECTUEE, 4],
]);

const DemandeUnilateraleAvenantStepNumber = new Map([
  [DemandeStep.CO_CONSTRUCTION, 1],
  [DemandeStep.SIGNATURE, 2],
  [DemandeStep.COMPLETED, 3],
  [DemandeStep.MAIN_LEVEE, 3],
  [DemandeStep.MAIN_LEVEE_EFFECTUEE, 3],
]);

export function workflowStepToNumber(
  demandeStep: DemandeStep,
  isConvention: boolean,
  canSeeStepPreparation: boolean,
  isAvenant: boolean,
  isUnilaterale: boolean
): number {
  if (isConvention) {
    if (isUnilaterale) {
      if (isAvenant) {
        return (
          DemandeUnilateraleAvenantWorkflowCardNumber.get(demandeStep) ?? 0
        );
      }
      return DemandeUnilateraleWorkflowCardNumber.get(demandeStep) ?? 0;
    }

    if (canSeeStepPreparation && !isAvenant) {
      return ConventionWorkflowCardNumber.get(demandeStep) ?? 0;
    }
  }

  return AvenantOrAttestationWorkflowCardNumber.get(demandeStep) ?? 0;
}

export function demandeStepToNumber(
  demandeStep: DemandeStep,
  isConvention: boolean,
  canSeeStepPreparation: boolean,
  isAvenant: boolean,
  isUnilaterale: boolean
): number {
  if (isConvention) {
    if (isUnilaterale) {
      if (isAvenant) {
        return DemandeUnilateraleAvenantStepNumber.get(demandeStep) ?? 0;
      }

      return DemandeUnilateraleStepNumber.get(demandeStep) ?? 0;
    }
    if (canSeeStepPreparation && !isAvenant) {
      return ConventionStepNumber.get(demandeStep) ?? 0;
    }
  }

  return AvenantOrAttestationStepNumber.get(demandeStep) ?? 0;
}

export function buildDemandeSignataireCreateRequest(
  demande: Demande,
  newSignataire: { id: string },
  idRecipient: number
): DemandeSignataireCreateRequest {
  const signataireCreateList: DemandeSignataireCreate[] = demande.signataireList
    .filter(
      (signataire) =>
        signataire.idRecipient !== idRecipient &&
        signataire.user.id !== newSignataire.id
    )
    .map((signataire) => buildDemandeSignataireCreate(demande, signataire));
  signataireCreateList.push({
    idDemande: demande.id,
    idSignataire: newSignataire.id,
    idRecipient: idRecipient,
    type: SignataireType.INTERNE,
  });

  return {
    idDemande: demande.id,
    signatairePromoteurCount: demande.signatairePromoteurCount,
    signataireBanqueCount: demande.signataireBanqueCount,
    signataireCreateList,
  };
}

export function buildDemandeNewSignatairePromoteurCountCreateRequest(
  demande: Demande,
  signatairePromoteurCount: number
): DemandeSignataireCreateRequest {
  const signataireInterneList = demande.signataireList.map((signataire) => {
    return {
      idDemande: demande.id,
      idSignataire: signataire.user.id,
      idRecipient: signataire.idRecipient,
      type: SignataireType.INTERNE,
      user: signataire.user,
    };
  });

  const signataireExterneList = demande.signataireExterneList.map(
    (signataire) => {
      return {
        idDemande: demande.id,
        idSignataire: signataire.id,
        idRecipient: signataire.idRecipient,
        type: SignataireType.EXTERNE,
        user: {
          id: signataire.id,
          roles: [Role.BANQUE],
        },
      };
    }
  );

  const signataireList = [
    ...signataireInterneList,
    ...signataireExterneList,
  ].sort(
    (signataire1, signataire2) =>
      signataire1.idRecipient - signataire2.idRecipient
  );

  const signataireCreateList: DemandeSignataireCreate[] = signataireList
    .filter(
      (signataire, index) =>
        !hasRole(signataire.user.roles, Role.PROMOTEUR) ||
        (signataire.idRecipient === index + 1 &&
          index < signatairePromoteurCount)
    )
    .map((signataire, index) => {
      return {
        idDemande: demande.id,
        idSignataire: signataire.user.id,
        idRecipient: index + 1,
        type: signataire.type,
      };
    });

  return {
    idDemande: demande.id,
    signatairePromoteurCount,
    signataireBanqueCount: demande.signataireBanqueCount,
    signataireCreateList,
  };
}

function buildDemandeSignataireCreate(
  demande: Demande,
  signataire: SignataireHeavy
): DemandeSignataireCreate {
  return {
    idDemande: demande.id,
    idSignataire: signataire.user.id,
    idRecipient: signataire.idRecipient,
    type: SignataireType.INTERNE,
  };
}

export function buildDemandeNewSignataireBanqueCountCreateRequest(
  demande: Demande,
  signataireBanqueCount: number
): DemandeSignataireCreateRequest {
  const signataireInterneList = demande.signataireList.map((signataire) => {
    return {
      idDemande: demande.id,
      idSignataire: signataire.user.id,
      idRecipient: signataire.idRecipient,
      type: SignataireType.INTERNE,
      user: signataire.user,
    };
  });

  const signataireExterneList = demande.signataireExterneList.map(
    (signataire) => {
      return {
        idDemande: demande.id,
        idSignataire: signataire.id,
        idRecipient: signataire.idRecipient,
        type: SignataireType.EXTERNE,
        user: {
          id: signataire.id,
          roles: [Role.BANQUE],
        },
      };
    }
  );

  const signataireList = [
    ...signataireInterneList,
    ...signataireExterneList,
  ].sort(
    (signataire1, signataire2) =>
      signataire1.idRecipient - signataire2.idRecipient
  );

  const signataireCreateList: DemandeSignataireCreate[] = signataireList
    .filter(
      (signataire, index) =>
        !hasRole(signataire.user.roles, Role.BANQUE) ||
        (signataire.idRecipient === index + 1 && index < signataireBanqueCount)
    )
    .map((signataire, index) => {
      return {
        idDemande: demande.id,
        idSignataire: signataire.idSignataire,
        idRecipient: index + 1,
        type: signataire.type,
      };
    });

  return {
    idDemande: demande.id,
    signatairePromoteurCount: demande.signatairePromoteurCount,
    signataireBanqueCount,
    signataireCreateList,
  };
}

export function demandeRemainingBusinessDays(date: Date): number {
  return differenceInBusinessDays(new Date(date), new Date(Date.now()));
}

export function computeNumberElapsedDaysExpectedDateInDemande(
  date: Date
): number {
  const expectedDate = new Date(date);
  const currentDate = new Date(Date.now());
  const diffDate = currentDate.getTime() - expectedDate.getTime();
  // diffDate est en millisecondes, calcul des millisecondes en jours :
  // millisecondes / (1 jour en millisecondes === 1000 * 3600 * 24)
  return Math.floor(diffDate / (1000 * 3600 * 24));
}

export function isDemandeParent(
  demandeToCheck: Demande,
  demandeList: Demande[]
): boolean {
  return demandeList.some((demande) => demande.idParent === demandeToCheck.id);
}

export function getDemandeLastVersion(
  demande: Demande | ActeSecondaireLight
): Demande | ActeSecondaireLight | AvenantLight {
  return demande.avenantList.length > 0 ? demande.avenantList[0] : demande;
}

export function getDemandeOldVersions(
  demande: Demande | ActeSecondaireLight
): (Demande | AvenantLight)[] {
  const oldVersions: (Demande | AvenantLight)[] = [demande];
  if (demande.avenantList.length > 0) {
    const [, ...olderVersions] = demande.avenantList;
    oldVersions.unshift(...olderVersions);
  }

  return oldVersions;
}

export function isUserAllowedToUploadDemandeDocumentValidation(
  isUserBanque: boolean,
  isUserPromoteur: boolean,
  documentValidationList: DemandeDocumentValidation[]
): boolean {
  return (
    documentValidationList.filter((document) => {
      if (isUserBanque) {
        return document.user.groups
          .flatMap((group) => group.roles)
          .includes(Role.BANQUE);
      } else if (isUserPromoteur) {
        return document.user.groups
          .flatMap((group) => group.roles)
          .includes(Role.PROMOTEUR);
      } else {
        return false;
      }
    }).length === 0
  );
}

export function isUserAllowedToDeleteDemandeDocumentValidation(
  isUserBanque: boolean,
  isUserPromoteur: boolean,
  document: DemandeDocumentValidation
): boolean {
  if (
    isUserBanque &&
    document.user.groups.flatMap((group) => group.roles).includes(Role.BANQUE)
  )
    return true;
  return (
    isUserPromoteur &&
    document.user.groups
      .flatMap((group) => group.roles)
      .includes(Role.PROMOTEUR)
  );
}

export function isTypeConvention(
  value: Demande | ActeSecondaireLight | AvenantLight
): value is Demande {
  return value.hasOwnProperty("societeSupport");
}

export function formatDemandeWithActeSecondaire(
  demande: Demande
): (Demande | ActeSecondaireLight)[] {
  return [demande, ...demande.acteSecondaireList];
}

export function computeDemandeStatus(
  isDemandeWrapper: boolean,
  hasDemandeActeSecondaire: boolean,
  demandeStatus: DemandeStatus
): DemandeStatus {
  const mainleveeRelatedStatusList = [
    DemandeStatus.EN_ATTENTE_DE_MAINLEVEE,
    DemandeStatus.DATE_ACHEVEMENT_DEPASSEE,
    DemandeStatus.MAIN_LEVEE_REFUSEE,
    DemandeStatus.MAIN_LEVEE_EFFECTUEE,
  ];

  if (
    hasDemandeActeSecondaire &&
    !isDemandeWrapper &&
    mainleveeRelatedStatusList.includes(demandeStatus)
  ) {
    return DemandeStatus.SIGNE;
  }

  return demandeStatus;
}

export function isDemandeConvention(demande: Demande): boolean {
  return !demande.idDemandePrincipale;
}

export function flattenEnvelopeToDemandeList(
  demande: DemandeLight
): (ActeSecondaireLight | AvenantLight | DemandeLight)[] {
  const acteSecondaireWithAvenants = demande.acteSecondaireList
    ? demande.acteSecondaireList.flatMap((acte) => {
        return [{ ...acte }, ...acte.avenantList];
      })
    : [];
  const demandeAvenantList = demande.avenantList ? demande.avenantList : [];
  return [{ ...demande }, ...demandeAvenantList, ...acteSecondaireWithAvenants];
}

export function computeDemandeExpectedDateLabel(expectedDate: Date): string {
  return "Émission souhaitée : " + formatDate(new Date(expectedDate));
}

export function computeDemandeExpectedDateSoonLabel(date: Date): string {
  const remainingBusinessDays = demandeRemainingBusinessDays(date);
  if (remainingBusinessDays === 0) return "Émission souhaitée aujourd'hui";
  if (remainingBusinessDays > 1)
    return remainingBusinessDays + " jours ouvrés restants";
  return 1 + " jour ouvré restant";
}

export function computeDemandeSignedDateLabel(
  demande: DemandeLight | AvenantLight | ActeSecondaireLight
): string {
  const signMethod = demande.signMethod;
  if (isSignMethodManual(signMethod)) {
    return "Émis manuellement";
  } else if (isSignMethodElectronic(signMethod) && demande.signedAt) {
    return "Émis le : " + formatDate(demande.signedAt);
  }
  return "Date d'émission inconnue";
}

export function computeDemandeDatePassedLabel(expectedDate: Date): string {
  const passedDays =
    computeNumberElapsedDaysExpectedDateInDemande(expectedDate);
  return passedDays > 1
    ? "dépassé de " + passedDays + " jours"
    : "dépassé de " + 1 + " jour";
}

export function isDatePassed(expectedDate: Date): boolean {
  const expectedDateToCheck = new Date(expectedDate);
  const currentDate = new Date(Date.now());
  currentDate.setHours(0, 0, 0, 0);
  return expectedDateToCheck < currentDate;
}

export function formatAddDocumentLabel(documentListLength: number): string {
  switch (documentListLength) {
    case 0:
      return "Aucun document n’est ajouté à l’opération";
    case 1:
      return "01 document dans l'opération";
    default:
      return `${documentListLength
        .toString()
        .padStart(2, "0")} documents dans l'opération`;
  }
}

export function formatUploadedDocumentLabel(demande: Demande): string {
  const banqueJoinDate = demande.banqueJoinDate;
  return banqueJoinDate
    ? `Demande partagée le ${formatDateTime(banqueJoinDate)}`
    : "La demande n’est pas partagée";
}

export function canCreateDemandeAvenant(demande: Demande): boolean {
  const firstVersionDemande = demande.parent ?? demande;
  const demandePrincipale =
    firstVersionDemande.demandePrincipale ?? firstVersionDemande;
  const demandeLastVersion = getDemandeLastVersion(demandePrincipale);

  if (demandePrincipale.status === DemandeStatus.MAIN_LEVEE_EFFECTUEE) {
    return false;
  }
  if (demande.idDemandePrincipale) {
    return isDemandeSignedOrDatePassedOrMainLevee(
      getDemandeLastVersion(demande).status
    );
  }
  return isDemandeSignedOrDatePassedOrMainLevee(demandeLastVersion.status);
}

export function getDemandePrincipale(demande: Demande): Demande {
  const firstVersionDemande = demande.parent ?? demande;
  return firstVersionDemande.demandePrincipale ?? firstVersionDemande;
}

export function createDemandeHommeDeArt(): HommeDeArtDemandeHeavy {
  return {
    id: "",
    expectedDate: new Date(),
    numeroActe: "",
    operation: {
      id: "",
      name: "",
      adresseProgramme: "115 rue Cardinet",
      ville: "Paris",
      codePostal: "75017",
      region: "Ile-de-France",
    },
    demandeMainlevee: {
      id: "",
      status: MainleveeStatus.HOMME_DE_ART_INVITED,
      canSendToBanque: false,
    },
  };
}

export function canUploadAndEditDemandeDocument(
  idUser: string,
  isPromoteur: boolean,
  isBanque: boolean,
  isTiers: boolean,
  demandeTiersUserList: DemandeUtilisateurTiers[]
): boolean {
  if (isPromoteur || isBanque) {
    return true;
  } else if (isTiers) {
    const demandeRight = demandeTiersUserList.find(
      (demandeTier) => demandeTier.idUserTiers === idUser
    );
    if (!demandeRight) {
      return false;
    }
    if (demandeRight.tiersRole === TiersUserRole.EDITION_ACTE) {
      return true;
    }
  }
  return false;
}

export function formatDemandeArchiveWithActeSecondaire(
  demande: DemandeArchive
): (DemandeArchive | ActeSecondaireLightArchived)[] {
  return [demande, ...demande.acteSecondaireList];
}

export function getDemandeArchiveOldVersions(
  demande: DemandeArchive | ActeSecondaireLightArchived
): (DemandeArchive | AvenantLightArchived)[] {
  const oldVersions: (DemandeArchive | AvenantLightArchived)[] = [demande];
  if (demande.avenantList.length > 0) {
    const [, ...olderVersions] = demande.avenantList;
    oldVersions.unshift(...olderVersions);
  }

  return oldVersions;
}

export function isDemandePrincipale(demande: Demande): boolean {
  return !demande.idDemandePrincipale;
}
