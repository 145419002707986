export enum OperationDocumentCategory {
  AUTORISATION_ADMINISTRATIVES = "AUTORISATION_ADMINISTRATIVE",
  MAITRISE_FONCIERE = "MAITRISE_FONCIERE",
  FINANCIER = "FINANCIER",
  JURIDIQUE = "JURIDIQUE",
  TECHNIQUE = "TECHNIQUE",
  COMMERCIALISATION = "COMMERCIALISATION",
  ACTE = "ACTE",
  PROJET = "PROJET",
  AUTRE = "AUTRE",
  LETTRE_ACCORD = "LETTRE_ACCORD",
  ACHEVEMENT = "ACHEVEMENT",
  ACHEVEMENT_MAINLEVEE = "ACHEVEMENT_MAINLEVEE",
  IFPIM = "IFPIM",
  FINANCIER_SUIVI_BUDGET = "FINANCIER_SUIVI_BUDGET",
  SUIVI = "SUIVI",
  SUIVI_COMMERCIALISATION_DETAIL_LOTS = "SUIVI_COMMERCIALISATION_DETAIL_LOTS",
}
