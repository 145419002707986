import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { UserCreationRequest } from "@domain/dto/userCreationRequest";
import { UserCreationStatus } from "@domain/dto/userCreationStatus";
import userApi from "@/api/userApi";
import { CreationStatus } from "@domain/enum/creationStatus";
import { toastError, toastSuccess, toastWarn } from "@/utils/toastUtils";
import { useAnnuaireStore } from "@/store/annuaireModule.pinia";
import { UserUpdateRequest } from "@domain/dto/userUpdateRequest";
import { UserUpdateAdminRequest } from "@domain/dto/userUpdateAdminRequest";
import useKeycloakStore from "@/store/keycloakModule.pinia";
import { useUserProfileStore } from "./userProfileModule.pinia";
import { UserProfileMetaData } from "@/domain/userProfileMetaData";
import uniqBy from "lodash/uniqBy";

type TiersStateType = {
  userMetaDataList: UserProfileMetaData[];
};
export const useUserStore = defineStore(ModuleType.User, {
  state: (): TiersStateType => ({
    userMetaDataList: [],
  }),
  getters: {
    getTiersUserMetaDataList(state): UserProfileMetaData[] {
      return state.userMetaDataList;
    },
  },
  actions: {
    SetUserMetaDataList(userMetaDataList: UserProfileMetaData[]) {
      this.userMetaDataList = uniqBy(
        [...this.userMetaDataList, ...userMetaDataList],
        "id"
      );
    },
    async addUsers(
      userCreationRequestList: UserCreationRequest[]
    ): Promise<UserCreationStatus[] | void> {
      return userApi
        .addUsers(userCreationRequestList)
        .then(async (response) => {
          if (response.status === 201) {
            const successfulAdd = response.data.find((status) =>
              [
                CreationStatus.CREATION_SUCCESS,
                CreationStatus.MIGRATION_FROM_TIERS_TO_NORMAL_SUCCESS,
              ].includes(status.status)
            );
            const alreadyExistsStatus = response.data.find((status) =>
              [
                CreationStatus.ALREADY_EXISTS_AS_TIERS,
                CreationStatus.ALREADY_EXISTS_AS_NORMAL_USER,
              ].includes(status.status)
            );
            const failedStatus = response.data.find(
              (status) => status.status === CreationStatus.CREATION_FAILED
            );
            if (alreadyExistsStatus) {
              toastWarn(
                "Certaines invitations n’ont pas pu être envoyées. Les adresses mails fournies appartiennent déjà à des utilisateurs."
              );
            } else if (successfulAdd && !alreadyExistsStatus && !failedStatus) {
              toastSuccess("Vos invitations ont été envoyées avec succès");
            } else if (!successfulAdd && !alreadyExistsStatus && failedStatus) {
              toastError(
                "Une erreur s’est produite lors de l’envoi des invitations. Veuillez réessayer plus tard."
              );
            }
            await useAnnuaireStore().fetchGroupTree(true);
            return response.data;
          }
        })
        .catch(() =>
          toastError("Une erreur est survenue lors de l'invitation des users")
        );
    },

    async updateUserInformation(payload: {
      userUpdateRequest: UserUpdateRequest;
      userId: string;
    }): Promise<void> {
      return userApi
        .updateUserInformation(payload.userUpdateRequest)
        .then((response) => {
          if (response.status === 200) {
            useUserProfileStore().SetUserProfile(response.data);
            useKeycloakStore().refreshToken();
            toastSuccess(
              "Vos informations ont bien été enregistrées. Bienvenue sur NEOMI !"
            );
          } else {
            toastError(
              "Une erreur est survenue lors de la mise à jour de vos informations"
            );
          }
        })
        .catch(() => {
          toastError(
            "Une erreur est survenue lors de la mise à jour de vos informations"
          );
        });
    },

    async adminUpdateUserInformation(payload: {
      userUpdateAdminRequest: UserUpdateAdminRequest;
    }): Promise<void> {
      return userApi
        .adminUpdateUserInformation(payload.userUpdateAdminRequest)
        .then((response) => {
          if (response.status == 200)
            toastSuccess("Utilisateur mis à jour avec succès");
        })
        .catch(() => {
          toastError(
            "Une erreur est survenue lors de la mise à jour de cet utilisateur"
          );
        });
    },

    async resendEmailInvitation(payload: {
      userId: string;
      userEmail: string;
    }): Promise<void> {
      return userApi
        .resendEmailInvitation(payload.userId)
        .then(() =>
          toastSuccess(
            `Une invitation a été renvoyée à l'adresse ${payload.userEmail}`
          )
        )
        .catch(() =>
          toastError(
            `Une erreur est survenue lors du renvoi de l'invitation à l'adresse ${payload.userEmail}`
          )
        );
    },

    async disableUser(userId: string): Promise<void> {
      return userApi
        .disableUser(userId)
        .then(() => toastSuccess(`L'utilisateur a bien été supprimé`))
        .catch(() =>
          toastError(
            `Une erreur est survenue, merci de contacter le support à support@neomi.immo`
          )
        );
    },

    async deleteInvitation(idUser: string): Promise<void> {
      try {
        await userApi.deleteUser(idUser);
        await useAnnuaireStore().fetchGroupTree(true);
        toastSuccess(`L'invitation a bien été supprimée`);
      } catch (error) {
        console.debug(error);
        toastError(
          `Une erreur est survenue, merci de contacter le support à support@neomi.immo`
        );
      }
    },

    async fetchUserList(idUserList: string[]): Promise<void> {
      const nonExistingMetaDataIdList = idUserList.filter(
        (id) =>
          !this.userMetaDataList.map((metaData) => metaData.id).includes(id)
      );
      if (nonExistingMetaDataIdList.length) {
        userApi
          .fetchUsersByIdList(nonExistingMetaDataIdList)
          .then((response) => {
            this.SetUserMetaDataList(response.data);
          })
          .catch(() => {
            toastError(
              "Une erreur est survenue lors de la récupération des informations des utilisateurs"
            );
          });
      }
    },
  },
});
