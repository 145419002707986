export enum UserEventType {
  USER_JOINING_OPERATION = "USER_JOINING_OPERATION",
  USER_JOINING_OPERATION_WITH_NEW_PARTNER = "USER_JOINING_OPERATION_WITH_NEW_PARTNER",
  OPERATION_DOCUMENT_UPLOADED = "OPERATION_DOCUMENT_UPLOADED",

  DEMANDE_SHARED_WITH_BANQUE = "DEMANDE_SHARED_WITH_BANQUE",
  DEMANDE_CREATED = "DEMANDE_CREATED",
  DEMANDE_PROJECT_UPLOADED = "DEMANDE_PROJECT_UPLOADED",
  DEMANDE_PROJECT_VALIDATED = "DEMANDE_PROJECT_VALIDATED",
  DEMANDE_SIGN_METHOD_CHOSEN = "DEMANDE_SIGN_METHOD_CHOSEN",
  DEMANDE_SIGNED = "DEMANDE_SIGNED",
  DEMANDE_MAINLEVEE_VALIDATED = "DEMANDE_MAINLEVEE_VALIDATED",
  DEMANDE_MAINLEVEE_REFUSED = "DEMANDE_MAINLEVEE_REFUSED",
  DEMANDE_MAINLEVEE_REQUEST = "DEMANDE_MAINLEVEE_REQUEST",
  DEMANDE_MAINLEVEE_DOCUMENT_UPLOADED = "DEMANDE_MAINLEVEE_DOCUMENT_UPLOADED",

  LETTRE_ACCORD_CREATED = "LETTRE_ACCORD_CREATED",
  LETTRE_ACCORD_PROJECT_UPLOADED = "LETTRE_ACCORD_PROJECT_UPLOADED",
  LETTRE_ACCORD_VALIDATED = "LETTRE_ACCORD_VALIDATED",
  LETTRE_ACCORD_SIGN_READY = "LETTRE_ACCORD_SIGN_READY",
  LETTRE_ACCORD_SIGNED = "LETTRE_ACCORD_SIGNED",

  SURETE_CREATED = "SURETE_CREATED",
  SURETE_PROJECT_UPLOADED = "SURETE_PROJECT_UPLOADED",
  SURETE_PROJECT_VALIDATED = "SURETE_PROJECT_VALIDATED",
  SURETE_SIGN_READY = "SURETE_SIGN_READY",
  SURETE_SIGNED = "SURETE_SIGNED",

  POOL_JOIN_DEMANDE = "POOL_JOIN_DEMANDE",
  POOL_NEW_DOCUMENT_UPLOADED = "POOL_NEW_DOCUMENT_UPLOADED",
  POOL_DEMANDE_SIGNED = "POOL_DEMANDE_SIGNED",
  POOL_DEMANDE_MAINLEVEE_VALIDATED = "POOL_DEMANDE_MAINLEVEE_VALIDATED",

  USER_MESSAGE = "USER_MESSAGE",
  USER_MESSAGE_MENTION = "USER_MESSAGE_MENTION",

  DAILY_OPERATION_MESSAGE_SUMMARY = "DAILY_OPERATION_MESSAGE_SUMMARY",
  ACTE_SECONDAIRE_CREATED = "ACTE_SECONDAIRE_CREATED",
  DEMANDE_DATE_LIVRAISON_DEPASSEE = "DEMANDE_DATE_LIVRAISON_DEPASSEE",

  CONDITION_MISE_EN_PLACE_NEW_DOCUMENT = "CONDITION_MISE_EN_PLACE_NEW_DOCUMENT",
  CONDITION_MISE_EN_PLACE_SURETE_SIGNED = "CONDITION_MISE_EN_PLACE_SURETE_SIGNED",

  ACCORD_COMMERCIAL_PROJET_AJOUTE = "ACCORD_COMMERCIAL_PROJET_AJOUTE",
  ACCORD_COMMERCIAL_PROJET_VALIDE = "ACCORD_COMMERCIAL_PROJET_VALIDE",
  ACCORD_COMMERCIAL_PROJET_DECLINED = "ACCORD_COMMERCIAL_PROJET_DECLINED",
  ACCORD_COMMERCIAL_PROJET_PRET_A_SIGNE = "ACCORD_COMMERCIAL_PROJET_PRET_A_SIGNE",
  ACCORD_COMMERCIAL_PROJET_SIGNE = "ACCORD_COMMERCIAL_PROJET_SIGNE",

  SUIVI_DE_TRAVAUX_STEP_VALIDATION = "SUIVI_DE_TRAVAUX_STEP_VALIDATION",
  SUIVI_DE_COMMERCIALISATION_NEW_IMPORT = "SUIVI_DE_COMMERCIALISATION_NEW_IMPORT",
  SUIVI_DE_COMMERCIALISATION_EDITION_TRANCHE = "SUIVI_DE_COMMERCIALISATION_EDITION_TRANCHE",

  ESG_FORM_FINALIZED = "ESG_FORM_FINALIZED",
  ESG_FORM_NEW_VERSION = "ESG_FORM_NEW_VERSION",
  ESG_FORM_ASKED_BY_BANQUE = "ESG_FORM_ASKED_BY_BANQUE",

  OPERATION_AUTO_ARCHIVE = "OPERATION_AUTO_ARCHIVE",
  OPERATION_ARCHIVED_BY_PARTNER = "OPERATION_ARCHIVED_BY_PARTNER",

  SUIVI_BUDGET_PROMOTEUR_IMPORT = "SUIVI_BUDGET_PROMOTEUR_IMPORT",
  SUIVI_BUDGET_PROMOTEUR_ACTUALISATION_SUCCES = "SUIVI_BUDGET_PROMOTEUR_ACTUALISATION_SUCCES",
  SUIVI_BUDGET_PROMOTEUR_ACTUALISATION_ERREUR = "SUIVI_BUDGET_PROMOTEUR_ACTUALISATION_ERREUR",
}
