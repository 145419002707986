import { SuiviBudgetLigneType } from "@domain/enum/suiviBudgetLigneType";
import {
  SuiviBudgetPoste,
  SuiviBudgetPosteHavingLigneType,
} from "@domain/enum/suiviBudgetPoste";
import { CellValue } from "@domain/cellValue";
import { isAmount } from "@domain/utils/numberUtils";
import { SuiviBudgetPromoteurImport } from "@domain/dto/suiviBudgetPromoteurImport";
import { Group } from "@domain/dto/group";

type SuiviBudgetPosteLabelMapping = Record<SuiviBudgetPoste, string>;

const suiviBudgetPosteLabelMapping: SuiviBudgetPosteLabelMapping = {
  [SuiviBudgetPoste.TERRAIN_NUMERAIRE]: "Terrain (partie en numéraire)",
  [SuiviBudgetPoste.TERRAIN_NUMERAIRE_SANS_TVA]: "Terrain (numéraire sans TVA)",
  [SuiviBudgetPoste.TERRAIN_DATION]: "Terrain (partie en dation)",
  [SuiviBudgetPoste.FRAIS_ACQUISITION]: "Frais d'acquisition",
  [SuiviBudgetPoste.INDEMNITES]: "Indemnités",
  [SuiviBudgetPoste.COMMISSIONS]: "Commissions",
  [SuiviBudgetPoste.TAXES_PARTICIPATIONS]: "Taxes et participation",
  [SuiviBudgetPoste.DIVERS_FONCIER]: "Divers (Foncier)",
  [SuiviBudgetPoste.DEPOLLUTION]: "Dépollution",
  [SuiviBudgetPoste.DEMOLITION]: "Démolitions",
  [SuiviBudgetPoste.FONDATIONS]: "Fondations",
  [SuiviBudgetPoste.TRAVAUX]: "Travaux",
  [SuiviBudgetPoste.TRAVAUX_VRD]: "VRD",
  [SuiviBudgetPoste.TRAVAUX_IMPREVUS]: "Imprévus",
  [SuiviBudgetPoste.GEOMETRE]: "Géomètre",
  [SuiviBudgetPoste.ASSURANCES]: "Assurances",
  [SuiviBudgetPoste.HONORAIRES_ARCHITECTE]: "Honoraires architecte",
  [SuiviBudgetPoste.HONORAIRES_TECHNIQUES_DIVERS]:
    "Honoraires techniques divers",
  [SuiviBudgetPoste.FRAIS_ADMINISTRATIFS]: "Frais administratifs",
  [SuiviBudgetPoste.FRAIS_ANNEXES]: "Divers (Frais annexes)",
  [SuiviBudgetPoste.PUBLICITE]: "Publicité",
  [SuiviBudgetPoste.AIDES_VENTE]: "Aide à la vente",
  [SuiviBudgetPoste.HONORAIRES_COMMERCIALISATION]:
    "Honoraires de commercialisation",
  [SuiviBudgetPoste.HONORAIRES_GESTION]: "Honoraires de gestion",
  [SuiviBudgetPoste.COMMISSIONS_SUR_ENGAGEMENT]: "Commissions sur engagements",
  [SuiviBudgetPoste.INTERETS]: "Intérêts",
  [SuiviBudgetPoste.VENTE_TVA_20]: "Vente TVA 20%",
  [SuiviBudgetPoste.VENTE_TVA_10]: "Vente TVA 10%",
  [SuiviBudgetPoste.VENTE_TVA_5_5]: "Vente TVA 5,5%",
  [SuiviBudgetPoste.DATION]: "Dation (Recettes)",
  [SuiviBudgetPoste.DIVERS_RECETTES]: "Divers recettes",
  [SuiviBudgetPoste.IGNORE]: "Ignoré",
  [SuiviBudgetPoste.EMPTY]: "",
};

export function getSuiviBudgetPosteLabel(poste: SuiviBudgetPoste): string {
  return suiviBudgetPosteLabelMapping[poste];
}

type SuiviBudgetPosteLigneTypeMapping = Record<
  SuiviBudgetPosteHavingLigneType,
  SuiviBudgetLigneType
>;

const suiviBudgetPosteLigneTypeMapping: SuiviBudgetPosteLigneTypeMapping = {
  [SuiviBudgetPoste.TERRAIN_NUMERAIRE]: SuiviBudgetLigneType.TERRAIN,
  [SuiviBudgetPoste.TERRAIN_NUMERAIRE_SANS_TVA]: SuiviBudgetLigneType.TERRAIN,
  [SuiviBudgetPoste.TERRAIN_DATION]: SuiviBudgetLigneType.TERRAIN,
  [SuiviBudgetPoste.FRAIS_ACQUISITION]: SuiviBudgetLigneType.TERRAIN,
  [SuiviBudgetPoste.INDEMNITES]: SuiviBudgetLigneType.TERRAIN,
  [SuiviBudgetPoste.COMMISSIONS]: SuiviBudgetLigneType.TERRAIN,
  [SuiviBudgetPoste.TAXES_PARTICIPATIONS]: SuiviBudgetLigneType.TERRAIN,
  [SuiviBudgetPoste.DIVERS_FONCIER]: SuiviBudgetLigneType.TERRAIN,
  [SuiviBudgetPoste.DEPOLLUTION]: SuiviBudgetLigneType.TRAVAUX,
  [SuiviBudgetPoste.DEMOLITION]: SuiviBudgetLigneType.TRAVAUX,
  [SuiviBudgetPoste.FONDATIONS]: SuiviBudgetLigneType.TRAVAUX,
  [SuiviBudgetPoste.TRAVAUX]: SuiviBudgetLigneType.TRAVAUX,
  [SuiviBudgetPoste.TRAVAUX_VRD]: SuiviBudgetLigneType.TRAVAUX,
  [SuiviBudgetPoste.TRAVAUX_IMPREVUS]: SuiviBudgetLigneType.TRAVAUX,
  [SuiviBudgetPoste.GEOMETRE]: SuiviBudgetLigneType.HONORAIRES_TECHNIQUES,
  [SuiviBudgetPoste.ASSURANCES]: SuiviBudgetLigneType.HONORAIRES_TECHNIQUES,
  [SuiviBudgetPoste.HONORAIRES_ARCHITECTE]:
    SuiviBudgetLigneType.HONORAIRES_TECHNIQUES,
  [SuiviBudgetPoste.HONORAIRES_TECHNIQUES_DIVERS]:
    SuiviBudgetLigneType.HONORAIRES_TECHNIQUES,
  [SuiviBudgetPoste.FRAIS_ADMINISTRATIFS]: SuiviBudgetLigneType.FRAIS_ANNEXES,
  [SuiviBudgetPoste.FRAIS_ANNEXES]: SuiviBudgetLigneType.FRAIS_ANNEXES,
  [SuiviBudgetPoste.PUBLICITE]: SuiviBudgetLigneType.FRAIS_COMMERCIALISATION,
  [SuiviBudgetPoste.AIDES_VENTE]: SuiviBudgetLigneType.FRAIS_COMMERCIALISATION,
  [SuiviBudgetPoste.HONORAIRES_COMMERCIALISATION]:
    SuiviBudgetLigneType.FRAIS_COMMERCIALISATION,
  [SuiviBudgetPoste.HONORAIRES_GESTION]: SuiviBudgetLigneType.GESTION,
  [SuiviBudgetPoste.COMMISSIONS_SUR_ENGAGEMENT]:
    SuiviBudgetLigneType.FRAIS_FINANCIERS,
  [SuiviBudgetPoste.INTERETS]: SuiviBudgetLigneType.FRAIS_FINANCIERS,
  [SuiviBudgetPoste.VENTE_TVA_20]: SuiviBudgetLigneType.RECETTES,
  [SuiviBudgetPoste.VENTE_TVA_10]: SuiviBudgetLigneType.RECETTES,
  [SuiviBudgetPoste.VENTE_TVA_5_5]: SuiviBudgetLigneType.RECETTES,
  [SuiviBudgetPoste.DATION]: SuiviBudgetLigneType.RECETTES,
  [SuiviBudgetPoste.DIVERS_RECETTES]: SuiviBudgetLigneType.RECETTES,
};

export function getSuiviBudgetPosteLigneType(
  poste: SuiviBudgetPosteHavingLigneType
): SuiviBudgetLigneType {
  return suiviBudgetPosteLigneTypeMapping[poste];
}

export function convertSuiviBudgetLigneTypeToLabel(
  type: SuiviBudgetLigneType
): string {
  switch (type) {
    case SuiviBudgetLigneType.TERRAIN:
      return "Terrain";
    case SuiviBudgetLigneType.TRAVAUX:
      return "Travaux";
    case SuiviBudgetLigneType.HONORAIRES_TECHNIQUES:
      return "Honoraires techniques";
    case SuiviBudgetLigneType.FRAIS_ANNEXES:
      return "Frais annexes construction";
    case SuiviBudgetLigneType.FRAIS_COMMERCIALISATION:
      return "Frais commercialisation";
    case SuiviBudgetLigneType.GESTION:
      return "Gestion";
    case SuiviBudgetLigneType.FRAIS_FINANCIERS:
      return "Frais financiers";
    case SuiviBudgetLigneType.RECETTES:
      return "Recettes";
    default:
      return "";
  }
}

export function isCellEmpty(line: CellValue[], index: number): boolean {
  return line[index] === undefined || line[index] === "";
}

export function isCellOk(line: CellValue[], index: number): boolean {
  return isCellEmpty(line, index) || isAmount(line[index]);
}

export function attachBanqueReferenceNameToImportList(
  suiviBudgetPromoteurImportList: SuiviBudgetPromoteurImport[],
  groupLiteList: Group[]
): SuiviBudgetPromoteurImport[] {
  return suiviBudgetPromoteurImportList.map((suiviBudgetImport) => {
    return {
      ...suiviBudgetImport,
      banqueNameSelectedAsReference: groupLiteList.find(
        (banque) => banque.id === suiviBudgetImport.idBanqueSelectedAsReference
      )?.name,
    };
  });
}
