import {
  DashboardFilter,
  DashboardStatusTypeGroup,
  MainFilter,
} from "@domain/dto/dashboardFilter";
import { OperationLight } from "@/domain/dto/operationLight";
import { OperationSorting } from "@/domain/operationSorting";
import { SelectedDashboardTableFilters } from "@/domain/selectedDashboardTableFilters";
import { SelectedDashboardFilter } from "@/domain/selectedDashboardFilter";
import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { createSelectedDashboardFilter } from "@/utils/dashboardUtils";
import { OperationSortingType } from "@domain/enum/operationSortingType";
import { inverseOrder, SortingOrder } from "@domain/enum/sortingOrder";
import { OperationFilter } from "@/domain/operationFilter";
import { OperationFilterType } from "@/domain/enum/operationFilterType";
import { PoolAccessMode } from "@domain/enum/poolAccessMode";
import { DemandeStatus } from "@domain/enum/demandeStatus";
import { OperationType } from "@domain/enum/operationType";
import { getEngagementGlobalTypeToDemandeType } from "@/utils/demandeUtils";
import uniq from "lodash/uniq";
import { OperationSearchRequest } from "@domain/dto/operationSearchRequest";
import operationApi from "@/api/operationApi";
import { findReferentUserIdListFromOperationLightList } from "@/utils/referentUtils";
import useKeycloakStore from "@/store/keycloakModule.pinia";
import { getSetupContext } from "@/utils/vueUtils";
import { GroupSelectionFilterType } from "@/domain/enum/groupSelectionFilterType";
import { GroupTreeLite } from "@domain/dto/groupTreeLite";
import { DemandeType } from "@domain/enum/demandeType";

export const PAGE_SIZE = 50;

type DashboardStateType = {
  // List of operations in the dashboard
  operations: OperationLight[];
  // Filters and datas init values
  dashboardFilter: DashboardFilter;
  // main filter
  selectedDashboardFilter: SelectedDashboardFilter;
  // stat table filter demandeType/demandeStatus
  selectedDashboardTableFilters: SelectedDashboardTableFilters;
  // group filter (top left)
  operationIdGroupFilter: string;
  operationSorting: OperationSorting;
};

export const useDashboardStore = defineStore(ModuleType.Dashboard, {
  state: (): DashboardStateType => ({
    // List of operations in the dashboard
    operations: [],
    // Filters and datas init values
    dashboardFilter: {
      mainFilter: {
        regionList: [],
        engagementTypeList: [],
        demandeStatusList: [],
        bilateralPartnerList: [],
        unilateralPartnerNameList: [],
        childrenGroupList: [],
        poolAccessModeList: [],
        operationType: [],
      },
      dashboardStatusTypeGroupList: [],
    },
    // main filter
    selectedDashboardFilter: createSelectedDashboardFilter({}),
    // stat table filter demandeType/demandeStatus
    selectedDashboardTableFilters: {
      engagementGlobalTypeList: [],
      demandeStatusList: [],
    },
    // group filter (top left)
    operationIdGroupFilter: "0",
    operationSorting: {
      type: OperationSortingType.EXPECTED_DATE,
      order: SortingOrder.ASCENDING,
    },
  }),

  getters: {
    getDashboardOperations(state): OperationLight[] {
      return state.operations;
    },

    getDashboardOperationSorting(state): OperationSorting {
      return state.operationSorting;
    },

    getDashboardBilateralPartnerList(state): GroupTreeLite[] {
      return state.dashboardFilter.mainFilter.bilateralPartnerList;
    },

    getDashboardUnilateralPartnerNameList(state): string[] {
      return state.dashboardFilter.mainFilter.unilateralPartnerNameList;
    },

    getSelectedDashboardFilters(state): SelectedDashboardFilter {
      return state.selectedDashboardFilter;
    },

    getOperationIdGroupFilter(state): string {
      return state.operationIdGroupFilter;
    },

    getDashboardMainFilter(state): MainFilter {
      return state.dashboardFilter.mainFilter;
    },

    getDashboardStatusTypeGroupList(state): DashboardStatusTypeGroup[] {
      return state.operationIdGroupFilter === "0"
        ? state.dashboardFilter.dashboardStatusTypeGroupList
        : state.dashboardFilter.dashboardStatusTypeGroupList.filter(
            (demandeStatusTypeGroup) =>
              demandeStatusTypeGroup.unilateralPartnerName ===
                state.operationIdGroupFilter ||
              demandeStatusTypeGroup.bilateralPartner?.id ===
                state.operationIdGroupFilter ||
              demandeStatusTypeGroup.bilateralPartner?.parent?.id ===
                state.operationIdGroupFilter
          );
    },

    getSelectedDashboardTableFilters(state): SelectedDashboardTableFilters {
      return state.selectedDashboardTableFilters;
    },
  },
  actions: {
    AddDashboardOperation(operation: OperationLight): void {
      this.operations.unshift(operation);
    },

    ResetDashboardSelectedFilters(): void {
      const selectedPartnerId = this.operationIdGroupFilter;
      this.selectedDashboardFilter = createSelectedDashboardFilter({
        partnerIdList: selectedPartnerId ? [selectedPartnerId] : [],
      });
      this.selectedDashboardTableFilters = {
        engagementGlobalTypeList: [],
        demandeStatusList: [],
      };
    },

    ResetSelectedDashboardTableFilters(): void {
      this.selectedDashboardTableFilters = {
        engagementGlobalTypeList: [],
        demandeStatusList: [],
      };
    },

    AddDashboardOperations(operations: OperationLight[]): void {
      this.operations = [...this.operations, ...operations];
    },

    InitDashboardOperations(operations: OperationLight[]): void {
      this.operations = operations;
    },

    SetDashboardSearchQuery(searchQuery: string): void {
      this.selectedDashboardFilter.searchQuery = searchQuery;
    },
    SetDashboardGroupUnilateralName(groupName: string): void {
      this.selectedDashboardFilter.selectedUnilateralGroup = groupName;
    },
    AddDashboardOperationSelectedFilter(filter: OperationFilter): void {
      switch (filter.type) {
        case OperationFilterType.POOL_ACCESS_MODE:
          this.selectedDashboardFilter.poolAccessModeList.push(
            filter.id as PoolAccessMode
          );
          break;
        case OperationFilterType.REGIONS:
          this.selectedDashboardFilter.regionList.push(filter.id);
          break;
        case OperationFilterType.GROUPS:
          this.selectedDashboardFilter.partnerIdList.push(filter.id);
          break;
        case OperationFilterType.ENGAGEMENT_TYPES:
          this.selectedDashboardFilter.engagementTypeList.push(
            filter.id as DemandeType
          );
          break;
        case OperationFilterType.DEMANDE_STATUS:
          this.selectedDashboardFilter.demandeStatusList.push(
            filter.id as DemandeStatus
          );
          break;
        case OperationFilterType.OPERATION_TYPE:
          this.selectedDashboardFilter.operationType.push(
            filter.id as OperationType
          );
          break;
      }
    },

    RemoveDashboardOperationFilter(filter: OperationFilter): void {
      switch (filter.type) {
        case OperationFilterType.POOL_ACCESS_MODE:
          this.selectedDashboardFilter.poolAccessModeList =
            this.selectedDashboardFilter.poolAccessModeList.filter(
              (existingFilter) => existingFilter !== filter.id
            );
          break;
        case OperationFilterType.REGIONS:
          this.selectedDashboardFilter.regionList =
            this.selectedDashboardFilter.regionList.filter(
              (existingFilter) => existingFilter !== filter.id
            );
          break;
        case OperationFilterType.GROUPS:
          this.selectedDashboardFilter.partnerIdList =
            this.selectedDashboardFilter.partnerIdList.filter(
              (partnerId) => partnerId !== filter.id
            );
          break;
        case OperationFilterType.ENGAGEMENT_TYPES:
          this.selectedDashboardFilter.engagementTypeList =
            this.selectedDashboardFilter.engagementTypeList.filter(
              (existingFilter) => existingFilter !== filter.id
            );
          break;
        case OperationFilterType.DEMANDE_STATUS:
          this.selectedDashboardFilter.demandeStatusList =
            this.selectedDashboardFilter.demandeStatusList.filter(
              (existingFilter) => existingFilter !== filter.id
            );
          break;
        case OperationFilterType.OPERATION_TYPE:
          this.selectedDashboardFilter.operationType =
            this.selectedDashboardFilter.operationType.filter(
              (existingFilter) => existingFilter !== filter.id
            );
          break;
      }
    },

    ResetFilterType(filterType: OperationFilterType): void {
      switch (filterType) {
        case OperationFilterType.POOL_ACCESS_MODE:
          this.selectedDashboardFilter.poolAccessModeList = [];
          break;
        case OperationFilterType.REGIONS:
          this.selectedDashboardFilter.regionList = [];
          break;
        case OperationFilterType.GROUPS:
          this.selectedDashboardFilter.partnerIdList = [];
          break;
        case OperationFilterType.ENGAGEMENT_TYPES:
          this.selectedDashboardFilter.engagementTypeList = [];
          break;
        case OperationFilterType.DEMANDE_STATUS:
          this.selectedDashboardFilter.demandeStatusList = [];
          break;
        case OperationFilterType.OPERATION_TYPE:
          this.selectedDashboardFilter.operationType = [];
          break;
      }
    },

    SetDashboardSortingType(sorting: OperationSortingType): void {
      this.operationSorting.type = sorting;
    },

    SetDashboardSortingOrder(): void {
      this.operationSorting.order = inverseOrder(this.operationSorting.order);
    },

    SetDashboardIdGroupFilter(idGroup: string): void {
      this.operationIdGroupFilter = idGroup;
    },

    InitializeDashboardFilterList(dashboardFilter: DashboardFilter): void {
      this.dashboardFilter = dashboardFilter;
    },

    InitializeSelectedDashboardFilters(): void {
      this.selectedDashboardFilter = createSelectedDashboardFilter({});
    },

    ResetSelectedPartner(): void {
      this.selectedDashboardFilter.partnerIdList = [];
    },

    SetDashboardTableFilter(
      selectDashboardTableFilters: SelectedDashboardTableFilters
    ): void {
      // remove old value in main selected filter
      const demandeStatusListToRemove =
        this.selectedDashboardTableFilters.demandeStatusList;
      const demandeTypeListToRemove = getEngagementGlobalTypeToDemandeType(
        this.selectedDashboardTableFilters.engagementGlobalTypeList
      );

      // Remove old filter
      this.selectedDashboardFilter.demandeStatusList =
        this.selectedDashboardFilter.demandeStatusList.filter(
          (demandeStatus) => !demandeStatusListToRemove.includes(demandeStatus)
        );
      this.selectedDashboardFilter.engagementTypeList =
        this.selectedDashboardFilter.engagementTypeList.filter(
          (engagementType) => !demandeTypeListToRemove.includes(engagementType)
        );

      // Add filter to table filter
      this.selectedDashboardTableFilters = selectDashboardTableFilters;

      // intersection available filters with tableFilters for status
      const selectedDemandeStatusList =
        this.dashboardFilter.mainFilter.demandeStatusList.filter((status) =>
          this.selectedDashboardTableFilters.demandeStatusList.includes(status)
        );

      // Add filter to main selected filters
      this.selectedDashboardFilter.demandeStatusList = uniq([
        ...this.selectedDashboardFilter.demandeStatusList,
        ...selectedDemandeStatusList,
      ]);

      this.selectedDashboardFilter.engagementTypeList = uniq([
        ...this.selectedDashboardFilter.engagementTypeList,
        ...getEngagementGlobalTypeToDemandeType(
          this.selectedDashboardTableFilters.engagementGlobalTypeList
        ).filter((demandeType) =>
          this.dashboardFilter.mainFilter.engagementTypeList.includes(
            demandeType
          )
        ),
      ]);
    },

    async fetchAllDashboardOperations(page: number): Promise<number> {
      const mainFilter: SelectedDashboardFilter =
        this.getSelectedDashboardFilters;
      const operationSorting: OperationSorting =
        this.getDashboardOperationSorting;
      const poolAccessModeList = useKeycloakStore().isPromoteur
        ? undefined
        : mainFilter.poolAccessModeList;
      const operationSearchRequest: OperationSearchRequest = {
        searchQuery: mainFilter.searchQuery,
        selectedPoolAccessModeList: poolAccessModeList,
        selectedRegionList: mainFilter.regionList,
        selectedIdGroupList: mainFilter.partnerIdList,
        selectedEngagementTypeList: mainFilter.engagementTypeList,
        selectedDemandeStatusList: mainFilter.demandeStatusList,
        operationType: mainFilter.operationType,
        operationSortingType: operationSorting.type,
        operationSortingOrder: operationSorting.order,
        selectedUnilateralGroup: mainFilter.selectedUnilateralGroup,
        page: page,
        pageSize: PAGE_SIZE,
      };
      const response = await operationApi.searchApi(operationSearchRequest);

      const operationLightList = response.data;

      const { referentStore } = getSetupContext();
      if (page === 0) {
        this.InitDashboardOperations(operationLightList);
      } else if (page > 0) {
        this.AddDashboardOperations(operationLightList);
      }

      const referentUserIdList =
        findReferentUserIdListFromOperationLightList(operationLightList);

      referentStore.fetchProfileImagesByUserIdList(referentUserIdList);

      return response.data.length;
    },

    async updateDashboardOperationFilters(
      filter: OperationFilter
    ): Promise<void> {
      this.ResetSelectedDashboardTableFilters();
      if (filter.value) {
        this.AddDashboardOperationSelectedFilter(filter);
      } else {
        this.RemoveDashboardOperationFilter(filter);
      }
    },

    async commitDashboardOperationFilters(): Promise<void> {
      await this.fetchAllDashboardOperations(0);
    },

    async resetFilterType(filterType: OperationFilterType): Promise<void> {
      this.ResetFilterType(filterType);
      await this.fetchAllDashboardOperations(0);
    },

    async updateDashboardOperationIdGroupFilter(
      idGroup: string,
      groupType: GroupSelectionFilterType
    ): Promise<void> {
      this.ResetSelectedPartner();
      this.SetDashboardGroupUnilateralName("");
      switch (groupType) {
        case GroupSelectionFilterType.normal:
          if (idGroup !== "0") {
            this.AddDashboardOperationSelectedFilter({
              id: idGroup,
              value: idGroup !== "0",
              type: OperationFilterType.GROUPS,
            });
          }

          this.SetDashboardIdGroupFilter(idGroup);

          break;
        case GroupSelectionFilterType.unilateral:
          this.SetDashboardGroupUnilateralName(idGroup);
          break;
      }

      await this.fetchAllDashboardOperations(0);
    },

    async fetchDashboardFilters(): Promise<void> {
      const response = await operationApi.fetchDashboardFilters();
      this.InitializeDashboardFilterList(response.data);
    },

    async changeSortingOrder(): Promise<void> {
      this.SetDashboardSortingOrder();
      await this.fetchAllDashboardOperations(0);
    },

    async changeSortingType(sortingType: OperationSortingType): Promise<void> {
      this.SetDashboardSortingType(sortingType);
      await this.fetchAllDashboardOperations(0);
    },

    async resetDashboardFilters(): Promise<void> {
      this.InitializeSelectedDashboardFilters();
      this.ResetSelectedDashboardTableFilters();
      await this.fetchAllDashboardOperations(0);
    },
  },
});
