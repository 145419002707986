import {
  ErrorCreationTableauSuiviBudget,
  ErrorCreationTableauSuiviBudgetType,
  SuiviBudgetBilanLigneCategory,
  SuiviBudgetBilanLigneType,
  SuiviBudgetCategoryPourcentage,
  SuiviBudgetLegendeType,
  SuiviBudgetLigneCategory,
  SuiviBudgetLigneCategoryCellData,
  SuiviBudgetLigneCategoryVariation,
  SuiviBudgetSousLigne,
  SuiviBudgetSousLigneCellData,
} from "@/domain/suiviBudget";
import {
  SuiviBudgetOperationImport,
  SuiviBudgetOperationImportCategory,
} from "@domain/dto/suiviBudgetOperationImport";
import { SuiviBudgetLigneType } from "@domain/enum/suiviBudgetLigneType";
import { Err, Ok, Result } from "@hqoss/monads";
import { sortAscending, sortDescending } from "./sortUtils";
import { formatDate } from "./dateUtils";
import {
  computedVariationPourcentage,
  computeVariationPourcentageValues,
  sum,
} from "./numberUtils";
import { groupBy } from "lodash";
import {
  hasSuiviBudgetPosteLigneType,
  SuiviBudgetPoste,
} from "@domain/enum/suiviBudgetPoste";
import { SuiviVariation, VariationType } from "@/domain/suiviVariation";
import { SuiviBudgetImportType } from "@domain/enum/suiviBudgetImportType";
import { SuiviBudgetPromoteurLigne } from "@domain/dto/suiviBudgetPromoteurLigne";
import {
  SuiviBudgetModeleCreationState,
  SuiviBudgetModeleLigneCreationState,
} from "@/store/suiviBudgetModule.pinia";
import { SuiviBudgetModeleLigneCreationRequest } from "@domain/dto/suiviBudgetModeleLigneCreationRequest";
import { BadgeVariant } from "@/domain/enum/badgeVariant";
import { SuiviBudgetModeleWithCompletion } from "@domain/dto/suiviBudgetModeleWithCompletion";
import { SuiviBudgetModeleHeavyWithLignes } from "@domain/dto/suiviBudgetModeleHeavy";
import { SuiviBudgetPromoteurImportWithLignes } from "@domain/dto/suiviBudgetPromoteurImport";
import { SuiviBudgetModeleCreationRequest } from "@domain/dto/suiviBudgetModeleCreationRequest";
import {
  getSuiviBudgetPosteLabel,
  getSuiviBudgetPosteLigneType,
} from "@domain/utils/suiviBudgetUtils";

export function convertSuiviBudgetBilanLigneTypeToLabel(
  type: SuiviBudgetBilanLigneType
): string {
  switch (type) {
    case SuiviBudgetBilanLigneType.MARGE:
      return "Marge";
    case SuiviBudgetBilanLigneType.PRIX_VENTE:
      return "Prix de vente";
    case SuiviBudgetBilanLigneType.PRIX_REVIENT_TOTAL:
      return "Prix de revient total";
    case SuiviBudgetBilanLigneType.TVA_RESIDUELLE:
      return "TVA résiduelle";
    default:
      return "";
  }
}

export function getColorBySuiviBudgetLigneType(
  type: SuiviBudgetLigneType
): string | undefined {
  switch (type) {
    case SuiviBudgetLigneType.TERRAIN:
      return "dark-purple";
    case SuiviBudgetLigneType.TRAVAUX:
      return "blue";
    case SuiviBudgetLigneType.HONORAIRES_TECHNIQUES:
      return "light-blue";
    case SuiviBudgetLigneType.FRAIS_ANNEXES:
      return "dark-green";
    case SuiviBudgetLigneType.FRAIS_COMMERCIALISATION:
      return "green";
    case SuiviBudgetLigneType.GESTION:
      return "yellow";
    case SuiviBudgetLigneType.FRAIS_FINANCIERS:
      return "dark-yellow";
    case SuiviBudgetLigneType.RECETTES:
      return undefined;
    default:
      return undefined;
  }
}

export function getColorBySuiviBudgetLegendeType(
  type: SuiviBudgetLegendeType
): string {
  switch (type) {
    case SuiviBudgetLegendeType.TERRAIN:
      return "dark-purple";
    case SuiviBudgetLegendeType.TRAVAUX:
      return "blue";
    case SuiviBudgetLegendeType.HONORAIRES_TECHNIQUES:
      return "light-blue";
    case SuiviBudgetLegendeType.FRAIS_ANNEXES:
      return "dark-green";
    case SuiviBudgetLegendeType.FRAIS_COMMERCIALISATION:
      return "green";
    case SuiviBudgetLegendeType.GESTION:
      return "yellow";
    case SuiviBudgetLegendeType.FRAIS_FINANCIERS:
      return "dark-yellow";
    case SuiviBudgetLegendeType.RECETTES:
      return "";
    case SuiviBudgetLegendeType.AUTRE:
      return "neutral-grey";
    default:
      return "";
  }
}

export function convertSuiviBudgetLegendeTypeToLabel(
  type: SuiviBudgetLegendeType
): string {
  switch (type) {
    case SuiviBudgetLegendeType.TERRAIN:
      return "Terrain";
    case SuiviBudgetLegendeType.TRAVAUX:
      return "Travaux";
    case SuiviBudgetLegendeType.HONORAIRES_TECHNIQUES:
      return "Honoraires techniques";
    case SuiviBudgetLegendeType.FRAIS_ANNEXES:
      return "Frais annexes";
    case SuiviBudgetLegendeType.FRAIS_COMMERCIALISATION:
      return "Frais commercialisation";
    case SuiviBudgetLegendeType.GESTION:
      return "Gestion";
    case SuiviBudgetLegendeType.FRAIS_FINANCIERS:
      return "Frais financiers";
    case SuiviBudgetLegendeType.RECETTES:
      return "Recettes";
    case SuiviBudgetLegendeType.AUTRE:
      return "Autre";
    default:
      return "";
  }
}

export function convertToSuiviBudgetBilanTableau(
  importList: SuiviBudgetOperationImport[]
): SuiviBudgetBilanLigneCategory[] {
  const sortedImportList = sortSuiviBudgetImportList(importList);
  const bilanPrixRevientTotal: SuiviBudgetBilanLigneCategory = {
    type: SuiviBudgetBilanLigneType.PRIX_REVIENT_TOTAL,
    cellList: sortedImportList.map(
      (importVersion) => importVersion.prixRevientCentime
    ),
  };
  const bilanPrixVente: SuiviBudgetBilanLigneCategory = {
    type: SuiviBudgetBilanLigneType.PRIX_VENTE,
    cellList: sortedImportList.map(
      (importVersion) => importVersion.prixVenteCentime
    ),
  };
  const bilanMarge: SuiviBudgetBilanLigneCategory = {
    type: SuiviBudgetBilanLigneType.MARGE,
    cellList: sortedImportList.map(
      (importVersion) => importVersion.margeAbsolueCentime
    ),
  };
  const bilanTvaResiduelle: SuiviBudgetBilanLigneCategory = {
    type: SuiviBudgetBilanLigneType.TVA_RESIDUELLE,
    cellList: sortedImportList.map(
      (importVersion) => importVersion.tvaResiduelleCentime
    ),
  };

  let bilanLigneCategoryList = [
    bilanPrixRevientTotal,
    bilanPrixVente,
    bilanMarge,
    bilanTvaResiduelle,
  ];
  if (sortedImportList.length >= 2) {
    bilanLigneCategoryList = addVariationToBilanLigneCategory(
      bilanLigneCategoryList
    );
  }

  return bilanLigneCategoryList;
}

export function convertToSuiviBudgetTableau(
  importList: SuiviBudgetOperationImport[]
): Result<SuiviBudgetLigneCategory[], ErrorCreationTableauSuiviBudget> {
  const sortedImportList = sortSuiviBudgetImportList(importList);
  if (sortedImportList.length === 0) {
    const erreur: ErrorCreationTableauSuiviBudget = {
      errorType: ErrorCreationTableauSuiviBudgetType.AUCUN_IMPORT,
      message: "Aucun import",
    };
    return Err(erreur);
  }

  const allLigneType = findAllLigneType(importList);

  const createdLigneCategoryList = allLigneType.map((ligneType) =>
    createLigne(sortedImportList, ligneType)
  );

  const createdLigneCategoryWithVariationList =
    addVariationPourcentageToLigneCategory(createdLigneCategoryList);

  const sortedLigneCategoryList = sortSuiviBudgetCategoryList(
    createdLigneCategoryWithVariationList
  );

  return Ok(sortedLigneCategoryList);
}

function createLigne(
  importList: SuiviBudgetOperationImport[],
  ligneType: SuiviBudgetLigneType
): SuiviBudgetLigneCategory {
  const allSousLigneType = findAllSousLigneType(importList, ligneType);

  const sousLigneList = allSousLigneType.map((sousLigneType) =>
    createSousLigne(importList, ligneType, sousLigneType)
  );

  const categoryCellList = createCategoryCellList(
    sousLigneList,
    importList.length
  );
  return { type: ligneType, sousLigneList, categoryCellList };
}

function createCategoryCellList(
  sousLigneList: SuiviBudgetSousLigne[],
  importLength: number
): SuiviBudgetLigneCategoryCellData[] {
  const cellDataList: SuiviBudgetLigneCategoryCellData[] = [];
  for (let i = 0; i < importLength; i++) {
    const cells: SuiviBudgetSousLigneCellData[] = [];
    for (let j = 0; j < sousLigneList.length; j++) {
      cells.push(sousLigneList[j].sousLigneCellList[i]);
    }

    const sumCell = sumCategoryCellData(cells);
    cellDataList.push(sumCell);
  }
  return cellDataList;
}

function sumCategoryCellData(
  cells: SuiviBudgetSousLigneCellData[]
): SuiviBudgetLigneCategoryCellData {
  return {
    totalTTCEuroCentime: sum(cells.map((cell) => cell.ttcEuroCentime)),
    totalHTEuroCentime: sum(cells.map((cell) => cell.htEuroCentime)),
  };
}

function createSousLigne(
  importList: SuiviBudgetOperationImport[],
  ligneType: SuiviBudgetLigneType,
  sousLigneType: SuiviBudgetPoste
): SuiviBudgetSousLigne {
  const sousLigneCellList: SuiviBudgetSousLigneCellData[] = [];
  for (const importElement of importList) {
    const foundCategory = importElement.categoryList.find(
      (category) => category.type === ligneType
    );

    if (foundCategory) {
      const foundSousLigne = foundCategory.ligneList.find(
        (ligne) => ligne.posteType === sousLigneType
      );

      if (foundSousLigne) {
        sousLigneCellList.push({
          htEuroCentime: foundSousLigne.htEuroCentime,
          ttcEuroCentime: foundSousLigne.ttcEuroCentime,
        });
      } else {
        sousLigneCellList.push({
          htEuroCentime: 0,
          ttcEuroCentime: 0,
        });
      }
    } else {
      sousLigneCellList.push({
        htEuroCentime: 0,
        ttcEuroCentime: 0,
      });
    }
  }
  return {
    type: ligneType,
    posteType: sousLigneType,
    sousLigneCellList,
  };
}

function findAllLigneType(
  importList: SuiviBudgetOperationImport[]
): SuiviBudgetLigneType[] {
  const ligneTypeList: SuiviBudgetLigneType[] = [];

  for (const importElement of importList) {
    for (const category of importElement.categoryList) {
      if (!ligneTypeList.includes(category.type)) {
        ligneTypeList.push(category.type);
      }
    }
  }

  const ligneTypeOrder = Object.values(SuiviBudgetLigneType);
  ligneTypeList.sort(
    (a, b) => ligneTypeOrder.indexOf(a) - ligneTypeOrder.indexOf(b)
  );
  return ligneTypeList;
}

function findAllSousLigneType(
  importList: SuiviBudgetOperationImport[],
  ligneType: SuiviBudgetLigneType
): SuiviBudgetPoste[] {
  const categorieList: SuiviBudgetOperationImportCategory[] = [];
  for (const importElement of importList) {
    const foundCategory = importElement.categoryList.find(
      (category) => category.type === ligneType
    );
    if (foundCategory) {
      categorieList.push(foundCategory);
    }
  }

  const posteList: SuiviBudgetPoste[] = [];
  for (const category of categorieList) {
    for (const ligne of category.ligneList) {
      if (!posteList.includes(ligne.posteType)) {
        posteList.push(ligne.posteType);
      }
    }
  }

  const posteOrder = Object.values(SuiviBudgetPoste);
  posteList.sort((a, b) => posteOrder.indexOf(a) - posteOrder.indexOf(b));
  return posteList;
}

function sortSuiviBudgetImportList(
  importList: SuiviBudgetOperationImport[]
): SuiviBudgetOperationImport[] {
  const result: SuiviBudgetOperationImport[] = [];
  const sortedImportList = [...importList].sort((i1, i2) => {
    return sortAscending(i1.createdAt, i2.createdAt);
  });
  if (sortedImportList.length === 0) {
    return result;
  }

  if (sortedImportList.length > 0) {
    result.push(sortedImportList[0]);
  }
  const otherImportList = [...sortedImportList.splice(1)].sort((i1, i2) => {
    return sortDescending(i1.createdAt, i2.createdAt);
  });

  return result.concat(otherImportList);
}

function sortSuiviBudgetCategoryList(
  categorieList: SuiviBudgetLigneCategory[]
): SuiviBudgetLigneCategory[] {
  const order = [
    SuiviBudgetLigneType.RECETTES,
    SuiviBudgetLigneType.TERRAIN,
    SuiviBudgetLigneType.TRAVAUX,
    SuiviBudgetLigneType.HONORAIRES_TECHNIQUES,
    SuiviBudgetLigneType.FRAIS_ANNEXES,
    SuiviBudgetLigneType.FRAIS_COMMERCIALISATION,
    SuiviBudgetLigneType.GESTION,
    SuiviBudgetLigneType.FRAIS_FINANCIERS,
  ];
  const categoryOrder = new Map<SuiviBudgetLigneType, number>();
  order.forEach((o, index) => {
    categoryOrder.set(o, index);
  });

  return [...categorieList].sort((c1, c2) => {
    const ordreC1 = categoryOrder.get(c1.type) ?? 0;
    const ordreC2 = categoryOrder.get(c2.type) ?? 0;
    return sortAscending(ordreC1, ordreC2);
  });
}

export function computeSuiviBudgetHeaderList(
  importList: SuiviBudgetOperationImport[]
): string[] {
  const sortedImportList = sortSuiviBudgetImportList(importList);

  return sortedImportList.map((importElement, index) => {
    if (index === 0) {
      return "Origine";
    }
    return formatDate(importElement.createdAt);
  });
}

export function getLatestSuiviBudgetImport(
  importList: SuiviBudgetOperationImport[]
): SuiviBudgetOperationImport | undefined {
  const sortedImportList = [...importList].sort((i1, i2) => {
    return sortAscending(i1.createdAt, i2.createdAt);
  });

  return sortedImportList.pop();
}

function filterCategoriesLegende(
  suiviBudget: SuiviBudgetOperationImport
): SuiviBudgetOperationImportCategory[] {
  return suiviBudget.categoryList.filter((categorie) =>
    [
      SuiviBudgetLigneType.TERRAIN,
      SuiviBudgetLigneType.TRAVAUX,
      SuiviBudgetLigneType.HONORAIRES_TECHNIQUES,
      SuiviBudgetLigneType.FRAIS_ANNEXES,
      SuiviBudgetLigneType.FRAIS_COMMERCIALISATION,
      SuiviBudgetLigneType.GESTION,
      SuiviBudgetLigneType.FRAIS_FINANCIERS,
    ].includes(categorie.type)
  );
}

export function convertSuiviBudgetLigneTypeToLegendeType(
  ligneType: SuiviBudgetLigneType
): SuiviBudgetLegendeType {
  switch (ligneType) {
    case SuiviBudgetLigneType.TERRAIN:
      return SuiviBudgetLegendeType.TERRAIN;
    case SuiviBudgetLigneType.TRAVAUX:
      return SuiviBudgetLegendeType.TRAVAUX;
    case SuiviBudgetLigneType.HONORAIRES_TECHNIQUES:
      return SuiviBudgetLegendeType.HONORAIRES_TECHNIQUES;
    case SuiviBudgetLigneType.FRAIS_ANNEXES:
      return SuiviBudgetLegendeType.FRAIS_ANNEXES;
    case SuiviBudgetLigneType.FRAIS_COMMERCIALISATION:
      return SuiviBudgetLegendeType.FRAIS_COMMERCIALISATION;
    case SuiviBudgetLigneType.GESTION:
      return SuiviBudgetLegendeType.GESTION;
    case SuiviBudgetLigneType.FRAIS_FINANCIERS:
      return SuiviBudgetLegendeType.FRAIS_FINANCIERS;
    case SuiviBudgetLigneType.RECETTES:
      return SuiviBudgetLegendeType.RECETTES;
  }
}

export function computeSuiviBudgetCategoryPourcentage(
  suiviBudget: SuiviBudgetOperationImport
): SuiviBudgetCategoryPourcentage[] {
  const categories = filterCategoriesLegende(suiviBudget);
  const sommeHTEuroCentime = sum(
    categories.map((category) => category.totalHTEuroCentime)
  );

  const categoryPourcentages = new Map();

  for (const category of categories) {
    const pourcentage = computedVariationPourcentage(
      category.totalHTEuroCentime,
      sommeHTEuroCentime
    );

    if (pourcentage === 0) {
      continue;
    }

    if (pourcentage < 4) {
      if (categoryPourcentages.has(SuiviBudgetLegendeType.AUTRE)) {
        // type assertion sans risque ici
        const otherPourcentage = categoryPourcentages.get(
          SuiviBudgetLegendeType.AUTRE
        ) as number;

        categoryPourcentages.set(
          SuiviBudgetLegendeType.AUTRE,
          otherPourcentage + pourcentage
        );
      } else {
        categoryPourcentages.set(SuiviBudgetLegendeType.AUTRE, pourcentage);
      }
      continue;
    }

    categoryPourcentages.set(
      convertSuiviBudgetLigneTypeToLegendeType(category.type),
      pourcentage
    );
  }

  return Array.from(categoryPourcentages).map<SuiviBudgetCategoryPourcentage>(
    ([category, pourcentage]) => ({ category, pourcentage })
  );
}

export function formatPourcentageMargeRelative(
  margeRelativePourcentage: number
): string {
  return Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(margeRelativePourcentage);
}

export function addVariationToBilanLigneCategory(
  bilanLigneList: SuiviBudgetBilanLigneCategory[]
): SuiviBudgetBilanLigneCategory[] {
  return bilanLigneList.map((bilanLigne) => {
    return {
      type: bilanLigne.type,
      cellList: bilanLigne.cellList,
      variation: computeVariationBilanLigneCategory(bilanLigne),
    };
  });
}

export function addVariationPourcentageToLigneCategory(
  ligneCategoryList: SuiviBudgetLigneCategory[]
): SuiviBudgetLigneCategory[] {
  return ligneCategoryList.map((ligneCategory) => {
    return {
      type: ligneCategory.type,
      categoryCellList: ligneCategory.categoryCellList,
      sousLigneList: addVariationPourcentageToSousLigne(
        ligneCategory.sousLigneList
      ),
      variation: computeVariationPourcentageLigneCategory(ligneCategory),
    };
  });
}

function computeVariationBilanLigneCategory(
  bilanLigneCategory: SuiviBudgetBilanLigneCategory
): SuiviVariation | undefined {
  if (
    bilanLigneCategory.cellList.length < 2 ||
    bilanLigneCategory.cellList[0] === bilanLigneCategory.cellList[1]
  ) {
    return undefined;
  }
  const original = bilanLigneCategory.cellList[0];
  const latest = bilanLigneCategory.cellList[1];

  const variationPourcentage = computeVariationPourcentageValues(
    original,
    latest
  );
  const ratio = computeVariationRatio(original, latest);
  const variationType = computeTypeVariationByBilanType(
    bilanLigneCategory.type,
    ratio
  );

  return { variationType, variationPourcentage, ratio };
}

function computeVariationPourcentageLigneCategory(
  ligneCategory: SuiviBudgetLigneCategory
): SuiviVariation | undefined {
  if (
    ligneCategory.categoryCellList.length < 2 ||
    ligneCategory.categoryCellList[0].totalHTEuroCentime ===
      ligneCategory.categoryCellList[1].totalHTEuroCentime
  ) {
    return undefined;
  }
  const original = ligneCategory.categoryCellList[0].totalHTEuroCentime;
  const latest = ligneCategory.categoryCellList[1].totalHTEuroCentime;

  const variationPourcentage = computeVariationPourcentageValues(
    original,
    latest
  );
  const ratio = computeVariationRatio(original, latest);
  const variationType = computeTypeVariationByType(ligneCategory.type, ratio);

  return { variationType, variationPourcentage, ratio };
}

export function computeVariationRatio(
  originalAmountEuroCentime: number,
  latestAmountEuroCentime: number
): number {
  let ratio = latestAmountEuroCentime / originalAmountEuroCentime - 1;
  if (
    (latestAmountEuroCentime < originalAmountEuroCentime &&
      latestAmountEuroCentime < 0) ||
    (latestAmountEuroCentime > originalAmountEuroCentime &&
      originalAmountEuroCentime < 0)
  ) {
    ratio *= -1;
  }
  return ratio;
}

function addVariationPourcentageToSousLigne(
  sousLigneList: SuiviBudgetSousLigne[]
): SuiviBudgetSousLigne[] {
  return sousLigneList.map((sousLigne) => {
    return {
      posteType: sousLigne.posteType,
      type: sousLigne.type,
      sousLigneCellList: sousLigne.sousLigneCellList,
      variation: computeVariationPourcentageSousLigne(sousLigne),
    };
  });
}

function computeVariationPourcentageSousLigne(
  sousLigne: SuiviBudgetSousLigne
): SuiviVariation | undefined {
  if (
    sousLigne.sousLigneCellList.length < 2 ||
    sousLigne.sousLigneCellList[0].htEuroCentime ===
      sousLigne.sousLigneCellList[1].htEuroCentime
  ) {
    return undefined;
  }
  const original = sousLigne.sousLigneCellList[0].htEuroCentime;
  const latest = sousLigne.sousLigneCellList[1].htEuroCentime;

  const variationPourcentage = computeVariationPourcentageValues(
    original,
    latest
  );
  const ratio = computeVariationRatio(original, latest);
  const variationType = computeTypeVariationByType(sousLigne.type, ratio);

  return { variationType, variationPourcentage, ratio };
}

export function computeMaximumVariationLigneCategory(
  ligneCategoryList: SuiviBudgetLigneCategory[]
): SuiviBudgetLigneCategoryVariation[] {
  const variationLigneList: VariationLigne[] = [];

  for (const ligneCategory of ligneCategoryList) {
    if (ligneCategory.categoryCellList.length >= 2) {
      const original = ligneCategory.categoryCellList[0].totalHTEuroCentime;
      const latest = ligneCategory.categoryCellList[1].totalHTEuroCentime;

      if (original !== latest) {
        variationLigneList.push({
          ligneCategory,
          categoryType: ligneCategory.type,
          variationAbsolue: Math.abs(latest - original),
          variationRatio: computeVariationRatio(original, latest),
          htEuroCentime: latest,
        });
      }
    }
  }

  const sortedVariationLigneList = [...variationLigneList].sort((v1, v2) => {
    return sortDescending(v1.variationAbsolue, v2.variationAbsolue);
  });

  const truncatedVariationLigneList = sortedVariationLigneList.slice(0, 2);

  return truncatedVariationLigneList.map((variationLigne) => ({
    categoryType: variationLigne.categoryType,
    variationType: computeTypeVariation(variationLigne),
    variationPourcentage: computeVariationPourcentageValues(
      variationLigne.ligneCategory.categoryCellList[0].totalHTEuroCentime,
      variationLigne.ligneCategory.categoryCellList[1].totalHTEuroCentime
    ),
    ratio: variationLigne.variationRatio,
    htEuroCentime: variationLigne.htEuroCentime,
  }));
}

interface VariationLigne {
  ligneCategory: SuiviBudgetLigneCategory;
  categoryType: SuiviBudgetLigneType;
  variationAbsolue: number;
  variationRatio: number;
  htEuroCentime: number;
}

function computeTypeVariation(variationLigne: VariationLigne): VariationType {
  return computeTypeVariationByType(
    variationLigne.categoryType,
    variationLigne.variationRatio
  );
}

function computeTypeVariationByType(
  categoryType: SuiviBudgetLigneType,
  ratio: number
): VariationType {
  const recetteTypeList = [SuiviBudgetLigneType.RECETTES];
  if (recetteTypeList.includes(categoryType)) {
    return ratio > 0 ? VariationType.GOOD : VariationType.BAD;
  } else {
    return ratio > 0 ? VariationType.BAD : VariationType.GOOD;
  }
}

function computeTypeVariationByBilanType(
  bilanType: SuiviBudgetBilanLigneType,
  ratio: number
): VariationType {
  const recetteTypeList = [
    SuiviBudgetBilanLigneType.PRIX_VENTE,
    SuiviBudgetBilanLigneType.MARGE,
  ];
  if (recetteTypeList.includes(bilanType)) {
    return ratio > 0 ? VariationType.GOOD : VariationType.BAD;
  } else {
    return ratio > 0 ? VariationType.BAD : VariationType.GOOD;
  }
}

export type TrancheImportList = {
  nomTranche: string;
  importList: SuiviBudgetOperationImport[];
};

export function splitSuiviBudgetOperationImportListByTranche(
  budgetImportList: SuiviBudgetOperationImport[]
): TrancheImportList[] {
  const importListByNomTranche = groupBy(
    budgetImportList,
    (importElement) => importElement.nomTranche
  );

  const trancheImportListList: TrancheImportList[] = [];
  for (const [nomTranche, importList] of Object.entries(
    importListByNomTranche
  )) {
    trancheImportListList.push({
      nomTranche,
      importList,
    });
  }

  return trancheImportListList;
}

export function computeNomTrancheList(
  suiviImportList: SuiviBudgetOperationImport[]
): string[] {
  const suiviTrancheList = suiviImportList.filter(
    (suivi) => suivi.type === SuiviBudgetImportType.TRANCHE
  );
  const importListByNomTranche = groupBy(
    suiviTrancheList,
    (importElement) => importElement.nomTranche
  );

  return Object.keys(importListByNomTranche);
}

function createModelePosteMap(
  modeleSelected?: SuiviBudgetModeleHeavyWithLignes
): Map<string, SuiviBudgetPoste> {
  const posteMap = new Map<string, SuiviBudgetPoste>();

  if (modeleSelected) {
    for (const modeleLigne of modeleSelected.lignes) {
      posteMap.set(modeleLigne.nomPostePromoteur, modeleLigne.nomPosteNeomi);
    }
  }

  return posteMap;
}

function sortModeleLigneCreationStateListForModeleReutilisation(
  ligneCreationStateList: SuiviBudgetModeleLigneCreationState[]
): SuiviBudgetModeleLigneCreationState[] {
  return ligneCreationStateList.toSorted((previousLigne, nextLigne) => {
    if (previousLigne.nomPosteNeomi === SuiviBudgetPoste.EMPTY) {
      return -1;
    }
    if (nextLigne.nomPosteNeomi === SuiviBudgetPoste.EMPTY) {
      return 1;
    }
    return 0;
  });
}

export function buildSuiviBudgetModeleLigneCreationInitialStateList(
  promoteurLigneList: SuiviBudgetPromoteurLigne[],
  modeleSelected?: SuiviBudgetModeleHeavyWithLignes
): SuiviBudgetModeleLigneCreationState[] {
  const modelePosteMap = createModelePosteMap(modeleSelected);
  const defaultPoste = !!modeleSelected
    ? SuiviBudgetPoste.EMPTY
    : SuiviBudgetPoste.IGNORE;

  const ligneCreationStateList = promoteurLigneList.map((ligne) => ({
    ...ligne,
    selected: false,
    nomPosteNeomi: modelePosteMap.get(ligne.name) ?? defaultPoste,
  }));

  if (modeleSelected) {
    return sortModeleLigneCreationStateListForModeleReutilisation(
      ligneCreationStateList
    );
  }

  return ligneCreationStateList;
}

function buildSuiviBudgetModeleListCreationRequestList(
  ligneCreationStateList: SuiviBudgetModeleLigneCreationState[]
): SuiviBudgetModeleLigneCreationRequest[] {
  return ligneCreationStateList.map((ligne) => ({
    nomPosteNeomi: ligne.nomPosteNeomi,
    nomPostePromoteur: ligne.name,
  }));
}

export function buildSuiviBudgetModeleCreationRequestFromCreationState(
  creationState: SuiviBudgetModeleCreationState
): SuiviBudgetModeleCreationRequest {
  return {
    name: creationState.name,
    lignes: buildSuiviBudgetModeleListCreationRequestList(creationState.lignes),
  };
}

export function computeLabelCompletionReutilisationModele(
  modele: SuiviBudgetModeleWithCompletion | null
): string {
  return modele ? `${modele.completedLignes}/${modele.totalLignes} lignes` : "";
}

export function computeBadgeCompletionReutilisationModele(
  modele: SuiviBudgetModeleWithCompletion | null
): BadgeVariant {
  if (modele) {
    const completionPercents = computedVariationPourcentage(
      modele.completedLignes,
      modele.totalLignes
    );

    if (completionPercents === 100) {
      return BadgeVariant.SUCCESS;
    }

    return completionPercents > 0 ? BadgeVariant.WARNING : BadgeVariant.ERROR;
  }

  return BadgeVariant.INFO;
}

export function isCreationStateCompleteRelativelyToImport(
  suiviBudgetCreationState: SuiviBudgetModeleCreationState,
  suiviBudgetImport: SuiviBudgetPromoteurImportWithLignes
): boolean {
  const importLignes = suiviBudgetImport.lignes;
  const modeleLignes = suiviBudgetCreationState.lignes;

  const mappedLignes = importLignes.filter((ligne) => {
    return modeleLignes.some(
      (modeleLigne) =>
        modeleLigne.name === ligne.name &&
        modeleLigne.nomPosteNeomi !== SuiviBudgetPoste.EMPTY
    );
  });

  const completedLignes = mappedLignes.length;
  const totalLignes = importLignes.length;

  const completionPercents = computedVariationPourcentage(
    completedLignes,
    totalLignes
  );

  return completionPercents === 100;
}

export enum SuiviBudgetModeleValidationStatus {
  OK,
  AUCUNE_LIGNE_RECETTE,
  RECETTE_NULLE,
}

export function validateSuiviBudgetModeleCreationState(
  suiviBudgetCreationState: SuiviBudgetModeleCreationState
): SuiviBudgetModeleValidationStatus {
  const postesRecette = suiviBudgetCreationState.lignes.filter((ligne) => {
    if (!hasSuiviBudgetPosteLigneType(ligne.nomPosteNeomi)) {
      return false;
    }

    const ligneType = getSuiviBudgetPosteLigneType(ligne.nomPosteNeomi);
    return ligneType === SuiviBudgetLigneType.RECETTES;
  });

  if (postesRecette.length === 0) {
    return SuiviBudgetModeleValidationStatus.AUCUNE_LIGNE_RECETTE;
  }

  const totalRecettes = sum(postesRecette.map((ligne) => ligne.htEuroCentime));

  if (totalRecettes === 0) {
    return SuiviBudgetModeleValidationStatus.RECETTE_NULLE;
  }

  return SuiviBudgetModeleValidationStatus.OK;
}

export function computeSuiviBudgetPosteOptions(): {
  label: string;
  value: SuiviBudgetPoste;
}[] {
  const orderList = [
    SuiviBudgetPoste.IGNORE,
    SuiviBudgetPoste.TERRAIN_NUMERAIRE,
    SuiviBudgetPoste.TERRAIN_NUMERAIRE_SANS_TVA,
    SuiviBudgetPoste.TERRAIN_DATION,
    SuiviBudgetPoste.FRAIS_ACQUISITION,
    SuiviBudgetPoste.INDEMNITES,
    SuiviBudgetPoste.COMMISSIONS,
    SuiviBudgetPoste.TAXES_PARTICIPATIONS,
    SuiviBudgetPoste.DIVERS_FONCIER,
    SuiviBudgetPoste.DEPOLLUTION,
    SuiviBudgetPoste.DEMOLITION,
    SuiviBudgetPoste.FONDATIONS,
    SuiviBudgetPoste.TRAVAUX,
    SuiviBudgetPoste.TRAVAUX_VRD,
    SuiviBudgetPoste.TRAVAUX_IMPREVUS,
    SuiviBudgetPoste.GEOMETRE,
    SuiviBudgetPoste.ASSURANCES,
    SuiviBudgetPoste.HONORAIRES_ARCHITECTE,
    SuiviBudgetPoste.HONORAIRES_TECHNIQUES_DIVERS,
    SuiviBudgetPoste.FRAIS_ADMINISTRATIFS,
    SuiviBudgetPoste.FRAIS_ANNEXES,
    SuiviBudgetPoste.PUBLICITE,
    SuiviBudgetPoste.AIDES_VENTE,
    SuiviBudgetPoste.HONORAIRES_COMMERCIALISATION,
    SuiviBudgetPoste.HONORAIRES_GESTION,
    SuiviBudgetPoste.COMMISSIONS_SUR_ENGAGEMENT,
    SuiviBudgetPoste.INTERETS,
    SuiviBudgetPoste.VENTE_TVA_20,
    SuiviBudgetPoste.VENTE_TVA_10,
    SuiviBudgetPoste.VENTE_TVA_5_5,
    SuiviBudgetPoste.DATION,
    SuiviBudgetPoste.DIVERS_RECETTES,
  ];
  return orderList.map((poste) => ({
    label: getSuiviBudgetPosteLabel(poste),
    value: poste,
  }));
}
