import { EtapeImportConfigurationType } from "@/domain/enum/etapeImportConfigurationType";
import { EtapeImportConfiguration } from "@/domain/etapeImportConfiguration";
import { ImportConfiguration } from "@/domain/importConfiguration";
import { isPositiveInteger } from "@domain/validators/utils";
import { unzip, zip } from "lodash";

export const UNCONFIGURED_COLUMN_INDEX = -1;

export function buildInitialImportConfiguration<T extends string>(
  stepKeys: T[]
): ImportConfiguration<T> {
  return stepKeys.reduce(
    (acc, key) => ({ ...acc, [key]: UNCONFIGURED_COLUMN_INDEX }),
    {} as ImportConfiguration<T>
  );
}

export enum ImportConfigurationErrorType {
  REQUIRED_STEP_CONFIGURATION_MISSING = "REQUIRED_STEP_CONFIGURATION_MISSING",
  INVALID_STEP_CONFIGURATION = "INVALID_STEP_CONFIGURATION",
}

export interface ImportConfigurationError<T extends string> {
  type: ImportConfigurationErrorType;
  key: T;
}

export function getImportConfigurationErrors<T extends string>(
  configuration: ImportConfiguration<T>,
  configurationSteps: EtapeImportConfiguration<T>[]
): ImportConfigurationError<T>[] {
  const errors: ImportConfigurationError<T>[] = [];

  for (const step of configurationSteps) {
    const key = step.key;
    const isOptional = !!step.optional;
    const isConfigured = configuration[step.key] !== UNCONFIGURED_COLUMN_INDEX;
    const isValid = isPositiveInteger(configuration[step.key]);

    if (isConfigured && !isValid) {
      errors.push({
        type: ImportConfigurationErrorType.INVALID_STEP_CONFIGURATION,
        key,
      });
    } else if (!isOptional && !isConfigured) {
      errors.push({
        type: ImportConfigurationErrorType.REQUIRED_STEP_CONFIGURATION_MISSING,
        key,
      });
    }
  }

  return errors;
}

export function areAllImportConfigurationStepsConfigured<T extends string>(
  importConfiguration: ImportConfiguration<T>
): boolean {
  return Object.values(importConfiguration).every(
    (columnIndex) => columnIndex !== UNCONFIGURED_COLUMN_INDEX
  );
}

export function getConfiguredImportColumnList<T extends string>(
  steps: EtapeImportConfiguration<T>[],
  configuration: ImportConfiguration<T>
): number[] {
  return getConfiguredImportColumnDeclarations(steps, configuration).map(
    (config) => config.column
  );
}

export interface ImportDeclaration<T extends string> {
  key: T;
  type: EtapeImportConfigurationType;
  column: number;
  optional?: boolean;
  defaultValue?: string;
}

function getConfiguredImportColumnDeclarations<T extends string>(
  steps: EtapeImportConfiguration<T>[],
  configuration: ImportConfiguration<T>
): ImportDeclaration<T>[] {
  const importDeclarations: ImportDeclaration<T>[] = [];

  for (const [key, col] of Object.entries(configuration)) {
    const step = steps.find((step) => step.key === key);
    if (
      isPositiveInteger(col) &&
      step &&
      step.type === EtapeImportConfigurationType.COLUMN
    ) {
      importDeclarations.push({
        key: step.key,
        type: step.type,
        column: col,
        optional: step.optional,
        defaultValue: step.defaultValue,
      });
    }
  }
  return importDeclarations;
}

export function getConfiguredImportHeaderList<T extends string>(
  steps: EtapeImportConfiguration<T>[],
  configuration: ImportConfiguration<T>
): number[] {
  return getConfiguredImportHeaderDeclarations(steps, configuration).map(
    (config) => config.column
  );
}

export function getConfiguredImportHeaderDeclarations<T extends string>(
  steps: EtapeImportConfiguration<T>[],
  configuration: ImportConfiguration<T>
): ImportDeclaration<T>[] {
  const importDeclarations: ImportDeclaration<T>[] = [];

  for (const [key, col] of Object.entries(configuration)) {
    const step = steps.find((step) => step.key === key);
    if (
      isPositiveInteger(col) &&
      step &&
      step.type === EtapeImportConfigurationType.HEADER
    ) {
      importDeclarations.push({
        key: step.key,
        type: step.type,
        column: col,
        optional: step.optional,
        defaultValue: step.defaultValue,
      });
    }
  }
  return importDeclarations;
}

function filterOutInvalidRows(
  matrix: string[][],
  columnList: number[]
): string[][] {
  return matrix.filter((row) => {
    for (const column of columnList) {
      if (!row[column]) {
        return false;
      }
    }

    return true;
  });
}

export function fillMatrixWithDefaults<T extends string>(
  matrix: string[][],
  importDeclarations: ImportDeclaration<T>[]
): string[][] {
  return matrix.map((row) => {
    const newRow = [...row];
    for (const config of importDeclarations) {
      if (
        !newRow[config.column] &&
        config.defaultValue &&
        isPositiveInteger(config.column)
      ) {
        newRow[config.column] = config.defaultValue;
      }
    }
    return newRow;
  });
}

export function getDataOnlyImportMatrix<T extends string>(
  matrix: string[][],
  steps: EtapeImportConfiguration<T>[],
  configuration: ImportConfiguration<T>
): string[][] {
  const configuredHeaderList = getConfiguredImportHeaderList(
    steps,
    configuration
  );

  const configuredColumnList = getConfiguredImportColumnDeclarations(
    steps,
    configuration
  );

  const matrixCropped = matrix.slice(Math.max(...configuredHeaderList) + 1);
  const matrixProcessed = fillMatrixWithDefaults(
    matrixCropped,
    configuredColumnList
  );

  const columnList = configuredColumnList.map((config) => config.column);

  return filterOutInvalidRows(matrixProcessed, columnList);
}

export function convertImportMatrixToPreviewData<T extends string>(
  matrix: string[][],
  steps: EtapeImportConfiguration<T>[],
  configuration: ImportConfiguration<T>
): string[][] {
  const configuredColumnList = getConfiguredImportColumnList(
    steps,
    configuration
  );

  const dataOnlyMatrix = getDataOnlyImportMatrix(matrix, steps, configuration);

  return filterAndSortMatrix(dataOnlyMatrix, configuredColumnList);
}

/** Tri et filtre les colonnes d'une matrice selon une liste de colonnes */
export function filterAndSortMatrix(
  matrix: string[][],
  columnList: number[]
): string[][] {
  const maxColumnLength = Math.max(...matrix.map((row) => row.length));

  const validColumnList = columnList.filter(
    (col) => col >= 0 && col < maxColumnLength
  );

  if (!validColumnList.length) {
    return [];
  }

  const transposed = zip(...matrix).filter(
    (row): row is string[] => row !== undefined
  );

  const sortedData = validColumnList.map((col) => transposed[col]);

  return unzip(sortedData);
}
