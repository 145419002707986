import { User } from "@domain/dto/user";
import { ProfileField } from "@/domain/enum/profileField";
import toLower from "lodash/toLower";
import { Ref } from "vue";
import isDate from "lodash/isDate";
import { isAmount } from "@domain/utils/numberUtils.ts";

export function isEmpty(word: string | undefined): boolean {
  return !word || word.trim() === "";
}

export function exceedSize(word: string, maxSize: number): boolean {
  return word.trim().length > maxSize;
}

export function isNumber(value: string | number): boolean {
  return !!RegExp(/^\d+$/).exec(value.toString());
}

export function isAlphanumeric(value: string): boolean {
  return /^[a-zA-Z0-9]+$/.test(value);
}

export function isCodePostal(value: string): boolean {
  return !!RegExp(/^(?:[0-8]\d|9[0-8])\d{3}$/).exec(value.toString());
}

export function isValidInteger(value: number): boolean {
  return Number.isInteger(value);
}

export function isUserInformationValidForSignature(user: User): string[] {
  const formErrors: string[] = [];
  if (isEmpty(user.firstName)) {
    formErrors.push("firstName");
  }
  if (isEmpty(user.lastName)) {
    formErrors.push("lastName");
  }
  if (isEmpty(user.phoneNumber)) {
    formErrors.push("phoneNumber");
  }
  return formErrors;
}

export function buildErrorMessage(formErrors: string[]): string {
  let errorMessage = "Votre profil est mal configuré, ";
  errorMessage += formErrors.length > 1 ? "les champs" : "le champ";

  if (formErrors.includes(ProfileField.FIRST_NAME)) {
    errorMessage += " prénom,";
  }
  if (formErrors.includes(ProfileField.LAST_NAME)) {
    errorMessage += " nom,";
  }
  // TODO check if conform phone number with regex
  if (formErrors.includes(ProfileField.PHONE_NUMBER)) {
    errorMessage += " numéro de téléphone";
  }
  if (errorMessage.endsWith(",")) {
    errorMessage = errorMessage.slice(0, errorMessage.length - 1);
  }
  errorMessage +=
    formErrors.length > 1 ? " doivent être remplis" : " doit être rempli";

  return errorMessage;
}

export function sanitizeSiren(siren: string | undefined): string {
  if (typeof siren !== "string") return "";
  return siren.replace(/\D+/g, "");
}

// TODO remplacer par un regex (doit format "12345" en "123 45" ou "123456789" en "123 456 789") pendant la saisie
export function formatDisplaySiren(siren: string | undefined): string {
  const parsedSiren = sanitizeSiren(siren).split("");
  let count = 0;
  let formattedSiren = "";
  parsedSiren.forEach((num) => {
    if (count >= 3) {
      formattedSiren += " " + num;
      count = 0;
    } else {
      formattedSiren += num;
    }
    count++;
  });
  return formattedSiren;
}

export function handleCAFormatUtil(chiffreAffaireRaw: string): string {
  let floatingPoint = "";
  let formattedCA = "";

  let chiffreAffaire = removeSpacesFromEuroAmount(chiffreAffaireRaw);

  if (
    chiffreAffaire.endsWith(".") ||
    (chiffreAffaire.endsWith(",") &&
      isAmount(chiffreAffaire.substring(0, chiffreAffaire.length - 1))) ||
    isAmount(chiffreAffaire)
  ) {
    let index = chiffreAffaire.length;

    if (chiffreAffaire.includes(",")) {
      index = chiffreAffaire.indexOf(",");
    }
    if (chiffreAffaire.includes(".")) {
      index = chiffreAffaire.indexOf(".");
    }

    floatingPoint = chiffreAffaire.substring(index, chiffreAffaire.length);
    chiffreAffaire = chiffreAffaire.substring(0, index);
    const regex = /,/g;
    formattedCA = parseFloat(chiffreAffaire)
      .toLocaleString("en-US")
      .replace(regex, " ");

    return formattedCA + floatingPoint;
  } else {
    return chiffreAffaire;
  }
}

export function removeSpacesFromEuroAmount(value: string | undefined): string {
  const regex = /\s/g;
  return value ? value.replace(regex, "") : "";
}

export function isEmailAddressValid(value?: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  return Boolean(value) && emailRegex.test(toLower(value));
}

export function validateEmail(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export function getInvalidEmailChars(email: string): string[] {
  const validCharsRegex = /^[a-zA-Z0-9._%+-@]+$/;
  return Array.from(
    new Set(email.split("").filter((char) => !validCharsRegex.test(char)))
  );
}

export function isPhoneNumberValid(value: string): boolean {
  return value.length === 10 && /^0[1-9]\d+$/.test(value);
}

export function isPositiveInteger(input: unknown): input is number {
  return typeof input === "number" && Number.isInteger(input) && input >= 0;
}

export function formHasChanged<T>(
  previousForm: Ref<T>,
  currentForm: Ref<T>,
  keysToCheck: (keyof T)[]
): boolean {
  for (const key of keysToCheck) {
    const previousValue = previousForm.value[key];
    const currentValue = currentForm.value[key];
    if (isDate(previousValue) && isDate(currentValue)) {
      if (previousValue.getDate() !== currentValue.getDate()) {
        return true;
      }
    } else if (previousForm.value[key] !== currentForm.value[key]) {
      return true;
    }
  }

  return false;
}
