import { formatDistance, format, parse, isMatch } from "date-fns";
import { fr } from "date-fns/locale";
import {
  isToday as today,
  isYesterday as yesterday,
  differenceInHours,
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
import { SortingOrder } from "@domain/enum/sortingOrder";

export const MILLISECOND_IN_SECOND = 1000;
export const MILLISECOND_IN_MINUTE = 60 * MILLISECOND_IN_SECOND;

export function formatDateTime(date: string | Date): string {
  return format(new Date(date), "dd/MM/yyyy à HH'h'mm").toString();
}

export function formatCommentUploadDate(date: string): string {
  return format(new Date(date), "HH'h'mm', 'dd/MM/yyyy").toString();
}

export function formatDate(
  date: string | Date | undefined,
  formatStr = "dd/MM/yyyy"
): string {
  if (!date) return "Non renseignée";
  return format(new Date(date), formatStr).toString();
}
export function formatDateMonthLongSpaces(
  date: string | Date | undefined
): string {
  try {
    if (!date) return "Non renseignée";
    return format(new Date(date), "dd LLL yyyy", {
      locale: fr,
    }).toString();
  } catch (error) {
    console.debug(error);
    return "Non renseignée";
  }
}
export function formatDateSpaces(date: string | Date | undefined): string {
  if (!date) return "Non renseignée";
  return format(new Date(date), "dd MM yyyy").toString();
}

export function formatTime(date: string | Date): string {
  return format(new Date(date), "HH':'mm").toString();
}

export function formatDateFileName(date: string | Date): string {
  return format(new Date(date), "yyyy-MM-dd").toString();
}

export function formatSignatureDate(date: string | Date): string {
  return format(new Date(date), "'le' dd/MM/yyyy à HH'h'mm").toString();
}

export function getDateFromNow(date: string): string {
  return formatDistance(new Date(date), Date.now(), { locale: fr });
}

export function isToday(date: string): boolean {
  const targetDay = new Date(date);
  return today(targetDay);
}

export function isYesterday(date: string): boolean {
  const targetDay = new Date(date);
  return yesterday(targetDay);
}

export function is2DaysAgo(date: string): boolean {
  const targetDay = new Date(date);
  targetDay.setDate(targetDay.getDate() + 1);
  return yesterday(targetDay);
}

export function currentYear(): number {
  return new Date().getFullYear();
}

export function isInThePast(date: Date): boolean {
  return date < new Date();
}

export function sortListByCreatedAt<T extends { createdAt?: Date }>(
  itemList: T[],
  direction:
    | SortingOrder.ASCENDING
    | SortingOrder.DESCENDING = SortingOrder.DESCENDING
): T[] {
  return [...itemList].sort((itemA, itemB) => {
    const isAscending = direction === SortingOrder.ASCENDING;

    if (itemA.createdAt === undefined) {
      return isAscending ? 1 : -1;
    }

    if (itemB.createdAt === undefined) {
      return isAscending ? -1 : 1;
    }

    const timeA = new Date(itemA.createdAt).getTime();
    const timeB = new Date(itemB.createdAt).getTime();

    return isAscending ? timeA - timeB : timeB - timeA;
  });
}

export function sortListByUpdatedAt<T extends { updatedAt?: Date }>(
  itemList: T[],
  direction:
    | SortingOrder.ASCENDING
    | SortingOrder.DESCENDING = SortingOrder.DESCENDING
): T[] {
  return [...itemList].sort((itemA, itemB) => {
    const isAscending = direction === SortingOrder.ASCENDING;

    if (itemA.updatedAt === undefined) {
      return isAscending ? 1 : -1;
    }

    if (itemB.updatedAt === undefined) {
      return isAscending ? -1 : 1;
    }

    const timeA = new Date(itemA.updatedAt).getTime();
    const timeB = new Date(itemB.updatedAt).getTime();

    return isAscending ? timeA - timeB : timeB - timeA;
  });
}

export function getYesterdayDate(date: Date): Date {
  const yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);
  return yesterday;
}

export function getLatestByCreatedAt<T extends { createdAt: Date }>(
  data: T[]
): T | undefined {
  if (data.length === 0) return undefined;

  return data.reduce((latest, current) => {
    return current.createdAt > latest.createdAt ? current : latest;
  });
}

export function getLatestByUpdatedAt<T extends { updatedAt: Date }>(
  data: T[]
): T | undefined {
  if (data.length === 0) return undefined;

  return data.reduce((latest, current) => {
    return current.updatedAt > latest.updatedAt ? current : latest;
  });
}

export function getElapsed(dateString: Date): string {
  const date = new Date(dateString);

  const now = new Date();

  const secondsDifference = differenceInSeconds(now, date);
  const minutesDifference = differenceInMinutes(now, date);

  if (secondsDifference < 60) {
    return `${differenceInSeconds(now, date).toString()}s`;
  } else if (minutesDifference < 60) {
    return `${differenceInMinutes(now, date).toString()}m`;
  } else if (today(date)) {
    return `${differenceInHours(now, date).toString()}h`;
  } else {
    return `${differenceInDays(now, date)}j`;
  }
}

export function dateToYYYYmmDD(date: Date): string {
  return format(date, "yyyy-MM-dd");
}

export function isValidDateFormat(date: string, format: string): boolean {
  try {
    return isMatch(date, format);
  } catch (error) {
    return false;
  }
}

export interface ConvertDateOptions {
  /** format de la date (date-fns) */
  format?: string;
  /** date par défaut si la conversion échoue @default new Date() */
  defaultValue?: Date;
}

export function convertStringToDate(
  date: string,
  opts?: ConvertDateOptions
): Date {
  const options = {
    format: "dd/MM/yyyy",
    defaultValue: new Date(),
    ...opts,
  };

  return isValidDateFormat(date, options.format)
    ? parse(date, options.format, new Date())
    : options.defaultValue;
}
