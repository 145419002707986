<template>
  <Modal v-if="showUserUpdateModal" modal-width="444px">
    <template #header>
      <h5>Compléter mes informations</h5>
    </template>
    <template #body>
      <div class="form-container">
        <p>
          Afin de finaliser votre inscription, <br />
          merci de compléter vos informations personnelles
        </p>
        <FormUpdateUserInfo
          v-if="isUserIdReady"
          :is-submitted="isSubmitted"
          :user="user"
          @update="updateUser"
        ></FormUpdateUserInfo>
      </div>
    </template>
    <template #footer>
      <div>
        <button
          class="primary"
          data-test="confirm-button"
          @click="updateUserInfo"
        >
          Valider
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import FormUpdateUserInfo from "@/components/form/FormUpdateUserInfo.vue";
import { UserUpdateRequest } from "@domain/dto/userUpdateRequest";
import { isPhoneNumberValid as isPhoneNumberValidUtil } from "@/utils/validationFormUtils";
import Modal from "@/components/shared/ModalComponent.vue";
import { capitaliseFirstAndLastName } from "@/utils/userUtils";
import { getSetupContext } from "@/utils/vueUtils";

import { computed, defineComponent, onMounted, ref } from "vue";
import { User } from "@domain/dto/user";

export default defineComponent({
  components: { FormUpdateUserInfo, Modal },
  setup() {
    const { userStore, keycloakStore, userProfileStore } = getSetupContext();

    const user = ref<UserUpdateRequest>({
      firstName: "",
      lastName: "",
    });
    const isSubmitted = ref<boolean>(false);

    const showUserUpdateModal = computed((): boolean => {
      return (
        keycloakStore.profileAvailable &&
        keycloakStore.hasReadLatestConditionGeneraleUtilisation &&
        !keycloakStore.hasAlreadyLoggedInProvidedInformation
      );
    });

    const currentUser = computed((): User => {
      return userProfileStore.getUserProfile;
    });

    const isUserIdReady = computed((): boolean => {
      return !!currentUser.value.id;
    });

    const isPhoneNumberValid = computed((): boolean => {
      if (!user.value.phoneNumber) {
        return true;
      } else {
        return isPhoneNumberValidUtil(user.value.phoneNumber);
      }
    });

    const isFormValid = computed((): boolean => {
      return (
        !!user.value.firstName &&
        !!user.value.lastName &&
        isPhoneNumberValid.value
      );
    });

    function updateUser(property: { [key: string]: string }): void {
      user.value = {
        ...user.value,
        ...property,
      };
      if (user.value.phoneNumber === "") {
        user.value.phoneNumber = undefined;
      }
    }

    function updateUserInfo(): void {
      isSubmitted.value = true;
      if (isFormValid.value) {
        const [firstName, lastName] = capitaliseFirstAndLastName(
          user.value.firstName,
          user.value.lastName
        );
        userStore.updateUserInformation({
          userId: currentUser.value?.id ?? "",
          userUpdateRequest: { ...user.value, firstName, lastName },
        });
      }
    }

    onMounted(() => {
      if (showUserUpdateModal.value) {
        user.value.phoneNumber = currentUser.value?.phoneNumber ?? undefined;
        user.value.jobTitle = currentUser.value?.jobTitle ?? undefined;
      }
    });

    return {
      // Data
      user,

      // Computed
      showUserUpdateModal,
      isFormValid,
      isUserIdReady,
      isSubmitted,

      // Methods,
      updateUserInfo,
      updateUser,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.form-container {
  background-color: $white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  width: 100%;
  align-items: flex-start;
}

h5 {
  margin: 0 8px;
}

p {
  margin-top: 0;
}
</style>
