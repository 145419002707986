export enum SuiviBudgetLigneType {
  TERRAIN = "TERRAIN",
  TRAVAUX = "TRAVAUX",
  HONORAIRES_TECHNIQUES = "HONORAIRES_TECHNIQUES",
  FRAIS_ANNEXES = "FRAIS_ANNEXES",
  FRAIS_COMMERCIALISATION = "FRAIS_COMMERCIALISATION",
  GESTION = "GESTION",
  FRAIS_FINANCIERS = "FRAIS_FINANCIERS",
  RECETTES = "RECETTES",
}
