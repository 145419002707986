import config from "@/config";
import { io, Socket } from "socket.io-client";
import { socketOptions, SockitEvent } from "./sockit";
import { GedTargetResource } from "@domain/enum/gedTargetResource";
import { FileStatus } from "@/domain/enum/fileStatus";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { useDocumentStore } from "@/store/documentModule.pinia";
import { useSuiviBudget } from "@/store/suiviBudgetModule.pinia";
import documentApi from "../documentApi";
import { useOperationStore } from "@/store/operationModule.pinia";
import { useSocieteSupportStore } from "@/store/societeSupportModule.pinia";
import { useDocumentESGEditStore } from "@/store/editESGDocumentModule.pinia";
import { GedType } from "@domain/enum/gedType";
import { useDemandeStore } from "@/store/demandeModule.pinia";
import { useLettreAccordStore } from "@/store/lettreAccordModule.pinia";
import { usePoolAccordCommercialStore } from "@/store/poolAccordCommercialModule.pinia";
import { useSureteStore } from "@/store/sureteModule.pinia";
import { useSuiviTravauxStore } from "@/store/suiviTravauxModule.pinia";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { handleSingleFileForMultipleDocumentUpload } from "@/utils/sockitUtils";
import { useLoaderStore } from "@/store/loaderModule.pinia";

const documentUploadFailStatusList = [
  FileStatus.ECHEC_TRANSMISSION,
  FileStatus.ECHEC_CHIFFREMENT,
  FileStatus.INCONNU,
];

export class FileSockitNamespace {
  private static instance: FileSockitNamespace;
  private sockit: Socket;

  private constructor(token: string) {
    this.sockit = io(`${config.ROOT_URL}/file`, {
      ...socketOptions,
      auth: { token },
    });
    this.bindEvents();
  }

  public static getInstance(token: string): FileSockitNamespace {
    if (!FileSockitNamespace.instance) {
      FileSockitNamespace.instance = new FileSockitNamespace(token);
    }

    return FileSockitNamespace.instance;
  }

  private bindEvents(): void {
    this.sockit.on(
      SockitEvent.CHANGED_FILE_STATUS,
      async (payload: {
        gedTargetResource: GedTargetResource;
        idDocument: string;
        name: string;
        status: FileStatus;
        idGedTargetResource?: string;
      }) => {
        useLoaderStore().removeLoaderByIdDocument(payload.idDocument);

        if (payload.status === FileStatus.VIRUS_DETECTE) {
          handleSingleFileForMultipleDocumentUpload({ isUploadSuccess: false });

          toastError(
            `Un virus a été détecté sur votre fichier (${payload.name})`
          );
        } else if (documentUploadFailStatusList.includes(payload.status)) {
          handleSingleFileForMultipleDocumentUpload({ isUploadSuccess: false });

          toastError(
            `Une erreur s'est produite lors du traitement de ${payload.name}`
          );
        } else if (payload.status === FileStatus.READY_TO_BE_DOWNLOADED) {
          const documentStore = useDocumentStore();

          if (documentStore.documentDownloadZipSubscribed[payload.idDocument]) {
            const operationName =
              documentStore.documentDownloadZipSubscribed[payload.idDocument];
            const fileName =
              payload.name === "" ? `${operationName}.zip` : payload.name;
            await documentApi.downloadQueuedOperationZipFile(
              payload.idDocument,
              fileName
            );
            delete documentStore.documentDownloadZipSubscribed[
              payload.idDocument
            ];
          }
        } else if (payload.status === FileStatus.UPLOADED) {
          if (
            payload.gedTargetResource === GedTargetResource.OPERATION_ESG_FORM
          ) {
            toastSuccess("Formulaire IFPIMM initialisé avec succès");
          } else if (payload.gedTargetResource === GedTargetResource.PROFILE) {
            toastSuccess(`Votre image de profil a été mis à jour avec succès`);
          } else {
            handleSingleFileForMultipleDocumentUpload({
              isUploadSuccess: true,
            });

            const isSingleDocumentUpload =
              useDocumentStore().isSingleDocumentUpload;

            if (isSingleDocumentUpload) {
              toastSuccess(`${payload.name} téléversé avec succès`);
            }
          }
          switch (payload.gedTargetResource) {
            case GedTargetResource.OPERATION: {
              if (
                useOperationStore().getCurrentOperationId !== "" ||
                useSocieteSupportStore().getSocieteSupport.id !== ""
              ) {
                await useDocumentStore().fetchAllDocuments();
                const doc =
                  useDocumentStore().FindOperationDocumentByIdDocument(
                    payload.idDocument
                  );
                if (!doc?.idOperation) {
                  break;
                }
              }
              break;
            }
            case GedTargetResource.OPERATION_SUIVI_COMMERCIALISATION_DETAIL_LOTS: {
              if (useOperationStore().getCurrentOperationId !== "") {
                await useDocumentStore().fetchLatestDocumentSuiviComplementaireList();
              }
              break;
            }
            case GedTargetResource.OPERATION_ESG_FORM:
            case GedTargetResource.EDITION_EN_LIGNE_ESG_FORM:
            case GedTargetResource.EDITION_EN_LIGNE_ESG_BROUILLON: {
              const operationStore = useOperationStore();
              if (operationStore.getCurrentOperationId !== "") {
                await operationStore.fetchOperationDetailsById(
                  operationStore.getCurrentOperationId
                );
                if (operationStore.getOperation.idEsgForm) {
                  await useDocumentESGEditStore().checkEditOperationESGBrouillonDocument(
                    {
                      idOperation: operationStore.getCurrentOperationId,
                      idFormDocument: operationStore.getOperation.idEsgForm,
                    }
                  );
                }
              }
              break;
            }

            case GedTargetResource.SOCIETE_SUPPORT: {
              if (useDocumentStore().gedType === GedType.SOCIETE_SUPPORT) {
                await useDocumentStore().fetchAllDocuments();
                const doc =
                  useDocumentStore().FindSocieteSupportDocumentByIdDocument(
                    payload.idDocument
                  );

                if (!doc?.idSocieteSupport) {
                  break;
                }
              }
              break;
            }

            case GedTargetResource.DEMANDE_PROJECT: {
              const demandeForProject = useDemandeStore().getDemande;

              if (
                !demandeForProject.id ||
                (demandeForProject.id &&
                  payload.idGedTargetResource !== demandeForProject.id)
              ) {
                break;
              }

              await Promise.all([
                useDemandeStore().fetchDemande({
                  idDemande: demandeForProject.id,
                  idOperation: demandeForProject.idOperation,
                }),
                useDemandeStore().fetchDemandeProject({
                  idDemande: demandeForProject.id,
                  idOperation: demandeForProject.idOperation,
                }),
              ]);

              break;
            }

            case GedTargetResource.DEMANDE_DOCUMENT_VALIDATION: {
              const demandeForDocumentValidation = useDemandeStore().getDemande;
              if (!demandeForDocumentValidation.id) {
                break;
              }
              await useDemandeStore().fetchDemande({
                idDemande: demandeForDocumentValidation.id,
                idOperation: demandeForDocumentValidation.idOperation,
              });
              break;
            }

            case GedTargetResource.DEMANDE_CERTIFICATE: {
              const demandeForCertificate = useDemandeStore().getDemande;
              if (
                !demandeForCertificate.id ||
                !demandeForCertificate.idOperation ||
                (payload.idGedTargetResource &&
                  demandeForCertificate.id !== payload.idGedTargetResource)
              ) {
                break;
              }

              await useDemandeStore().fetchDemande({
                idDemande: demandeForCertificate.id,
                idOperation: demandeForCertificate.idOperation,
              });
              await useDemandeStore().fetchDemandeCertificate({
                idDemande: demandeForCertificate.id,
                idOperation: demandeForCertificate.idOperation,
              });
              break;
            }

            case GedTargetResource.DEMANDE_ACTE_SIGNE: {
              const demandeForSignedActe = useDemandeStore().getDemande;
              if (
                !demandeForSignedActe.id ||
                !demandeForSignedActe.idOperation ||
                (payload.idGedTargetResource &&
                  demandeForSignedActe.id !== payload.idGedTargetResource)
              ) {
                break;
              }

              await useDemandeStore().fetchDemande({
                idDemande: demandeForSignedActe.id,
                idOperation: demandeForSignedActe.idOperation,
              });
              await useDemandeStore().fetchDemandeSignedAct({
                idDemande: demandeForSignedActe.id,
                idOperation: demandeForSignedActe.idOperation,
              });

              break;
            }

            case GedTargetResource.LETTRE_ACCORD_PROJECT: {
              const lettreAccord = useLettreAccordStore().getLettreAccord;
              if (lettreAccord.id) {
                await useLettreAccordStore().fetchLettreAccord({
                  idOperation: lettreAccord.idOperation,
                  idLettreAccord: lettreAccord.id,
                });
                await useLettreAccordStore().fetchLettreAccordProjectDocument();
              }
              break;
            }

            case GedTargetResource.LETTRE_ACCORD_CERTIFICATE: {
              const lettreAccordForCertificate =
                useLettreAccordStore().getLettreAccord;
              if (lettreAccordForCertificate.id) {
                await useLettreAccordStore().fetchLettreAccord({
                  idOperation: lettreAccordForCertificate.idOperation,
                  idLettreAccord: lettreAccordForCertificate.id,
                });
                await useLettreAccordStore().fetchLettreAccordCertificateDocument();
              }
              break;
            }

            case GedTargetResource.LETTRE_ACCORD_SIGNED_ACTE: {
              const lettreAccordForSignedActe =
                useLettreAccordStore().getLettreAccord;
              if (lettreAccordForSignedActe.id) {
                await useLettreAccordStore().fetchLettreAccord({
                  idOperation: lettreAccordForSignedActe.idOperation,
                  idLettreAccord: lettreAccordForSignedActe.id,
                });
                await useLettreAccordStore().fetchLettreAccordActeDocument();
              }
              break;
            }

            case GedTargetResource.POOL_ACCORD_COMMERCIAL_PROJECT: {
              const accordCommercialForProject =
                usePoolAccordCommercialStore().getAccordCommercial;

              if (!accordCommercialForProject.id) {
                break;
              }

              await Promise.all([
                usePoolAccordCommercialStore().fetchAccordCommercial(
                  accordCommercialForProject.idOperation,
                  accordCommercialForProject.id
                ),
                usePoolAccordCommercialStore().fetchAccordCommercialProjectDocument(
                  accordCommercialForProject.idOperation,
                  accordCommercialForProject.id
                ),
              ]);
              break;
            }

            case GedTargetResource.POOL_ACCORD_COMMERCIAL_CERTIFICATE: {
              const accordCommercialForCertificate =
                usePoolAccordCommercialStore().getAccordCommercial;
              if (accordCommercialForCertificate.id) {
                await usePoolAccordCommercialStore().fetchAccordCommercial(
                  accordCommercialForCertificate.idOperation,
                  accordCommercialForCertificate.id
                );
                await usePoolAccordCommercialStore().fetchAccordCommercialCertificateDocument(
                  accordCommercialForCertificate.idOperation,
                  accordCommercialForCertificate.id
                );
              }
              break;
            }

            case GedTargetResource.POOL_ACCORD_COMMERCIAL_SIGNED_ACTE: {
              const accordCommercialForSignedActe =
                usePoolAccordCommercialStore().getAccordCommercial;
              if (accordCommercialForSignedActe.id) {
                await usePoolAccordCommercialStore().fetchAccordCommercial(
                  accordCommercialForSignedActe.idOperation,
                  accordCommercialForSignedActe.id
                );
                await usePoolAccordCommercialStore().fetchAccordCommercialSignedActeDocument(
                  accordCommercialForSignedActe.idOperation,
                  accordCommercialForSignedActe.id
                );
              }
              break;
            }
            case GedTargetResource.SOCIETE_SUPPORT_SURETE_PROJECT: {
              const societeSupportSureteForProject = useSureteStore().getSurete;

              if (!societeSupportSureteForProject.id) {
                break;
              }

              await useSureteStore().fetchSocieteSupportSurete({
                idSocieteSupport:
                  societeSupportSureteForProject.idSocieteSupport,
                idSurete: societeSupportSureteForProject.id,
              });
              await useSureteStore().fetchSureteDocument();
              break;
            }

            case GedTargetResource.SURETE_CERTIFICATE: {
              const sureteForCertificate = useSureteStore().getSurete;

              if (sureteForCertificate.id) {
                await useSureteStore().fetchSocieteSupportSurete({
                  idSocieteSupport: sureteForCertificate.idSocieteSupport,
                  idSurete: sureteForCertificate.id,
                });
                await useSureteStore().fetchSureteCertificateDocument();
              }
              break;
            }

            case GedTargetResource.SURETE_SIGNED_ACTE: {
              const sureteForSignedActe = useSureteStore().getSurete;
              if (sureteForSignedActe.id) {
                await useSureteStore().fetchSocieteSupportSurete({
                  idSocieteSupport: sureteForSignedActe.idSocieteSupport,
                  idSurete: sureteForSignedActe.id,
                });
                await useSureteStore().fetchSureteSignedSureteDocument();
              }
              break;
            }

            case GedTargetResource.SUIVI_TRAVAUX_STEP_DOCUMENT: {
              const operation = useOperationStore().getOperation;

              if (!operation.id) {
                break;
              }

              await useSuiviTravauxStore().fetchSuiviTravaux(operation.id);
              break;
            }

            case GedTargetResource.PROFILE: {
              const userProfile = useUserProfileStore().getUserProfile;
              await useUserProfileStore().fetchUserProfile(userProfile.id);
              break;
            }

            case GedTargetResource.OPERATION_SUIVI_BUDGET_PROMOTEUR: {
              const operation = useOperationStore().getOperation;

              if (!operation.id) {
                break;
              }

              await useSuiviBudget().fetchSuiviBudgetList(operation.id);
              break;
            }
          }
        }

        useDocumentStore().ResetPendingDocumentUploadList();
      }
    );
  }
}
