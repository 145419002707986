import { SuiviBudgetLigneType } from "@domain/enum/suiviBudgetLigneType";
import { SuiviBudgetPoste } from "@domain/enum/suiviBudgetPoste";
import { SuiviVariation } from "@/domain/suiviVariation";

export interface SuiviBudgetLigneCategory {
  type: SuiviBudgetLigneType;
  variation?: SuiviVariation;
  categoryCellList: SuiviBudgetLigneCategoryCellData[];
  sousLigneList: SuiviBudgetSousLigne[];
}

export interface SuiviBudgetBilanLigneCategory {
  type: SuiviBudgetBilanLigneType;
  variation?: SuiviVariation;
  cellList: number[];
}

export enum SuiviBudgetBilanLigneType {
  PRIX_REVIENT_TOTAL = "PRIX_REVIENT_TOTAL",
  PRIX_VENTE = "PRIX_VENTE",
  MARGE = "MARGE",
  TVA_RESIDUELLE = "TVA_RESIDUELLE",
}

export interface ErrorCreationTableauSuiviBudget {
  errorType: ErrorCreationTableauSuiviBudgetType;
  message: string;
}

export enum ErrorCreationTableauSuiviBudgetType {
  AUCUN_IMPORT = "AUCUN_IMPORT",
  CATEGORIE_NON_TROUVEE = "CATEGORIE_NON_TROUVEE",
  SOUS_CATEGORIE_NON_TROUVEE = "SOUS_CATEGORIE_NON_TROUVEE",
}

export interface SuiviBudgetLigneCategoryCellData {
  totalHTEuroCentime: number;
  totalTTCEuroCentime: number;
}

export interface SuiviBudgetSousLigne {
  posteType: SuiviBudgetPoste;
  type: SuiviBudgetLigneType;
  variation?: SuiviVariation;
  sousLigneCellList: SuiviBudgetSousLigneCellData[];
}

export interface SuiviBudgetSousLigneCellData {
  htEuroCentime: number;
  ttcEuroCentime: number;
}

export interface SuiviBudgetCategoryPourcentage {
  category: SuiviBudgetLegendeType;
  pourcentage: number;
}

export enum SuiviBudgetLegendeType {
  TERRAIN = "TERRAIN",
  TRAVAUX = "TRAVAUX",
  HONORAIRES_TECHNIQUES = "HONORAIRES TECHNIQUES",
  FRAIS_ANNEXES = "FRAIS ANNEXES",
  FRAIS_COMMERCIALISATION = "FRAIS COMMERCIALISATION",
  GESTION = "GESTION",
  FRAIS_FINANCIERS = "FRAIS FINANCIERS",
  RECETTES = "RECETTES",
  AUTRE = "AUTRE",
}

export interface SuiviBudgetLigneCategoryVariation extends SuiviVariation {
  categoryType: SuiviBudgetLigneType;
  htEuroCentime: number;
}
